import { Country, State } from 'utils/types/glossaries';

type Address = {
	countryId?: number;
	stateId?: number;
	city?: string;
	countries: Array<Country> | null;
};

export const getUserLocation = <T extends Address>(
	{ countryId, stateId, city, countries }: T,
): string | null => {
	if (!countryId && !stateId && !city) return null;

	const country: Country | undefined = countries?.find(c => c.id === countryId);
	const state: State | undefined = country?.states?.find(s => s.id === stateId);

	const address = [];

	if (country) address.push(country.name);
	if (state) address.push(state.code);
	if (city) address.push(city);

	return address.join(', ');
};
