import { useProtectedAttachment } from 'utils/hooks';
import { Image } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import './ProtectedAttachment.scss';

type Props = {
	id: number;
};

const ProtectedAttachment = ({ id }: Props) => {
	if (!id) {
		return <FileImageOutlined style={{ fontSize: '30px' }} />;
	}

	const src = useProtectedAttachment(id);

	return (<Image preview={true} src={src} />);
};

export { ProtectedAttachment };
