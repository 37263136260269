import { Modal } from 'antd';
import { formatDate } from 'utils/helpers/date-time';
import { UserDTO } from 'utils/types/users';

type Props = {
	data: UserDTO;
	onClose: () => void;
};

const AdditionalInfoModal = ({ data, onClose }: Props): JSX.Element | null => (
	<Modal
		centered
		footer={null}
		onCancel={onClose}
		title="Additional Information"
		visible
	>
		<div>
			<p>
				Nickname:
				{' '}
				{data.nickname}
			</p>
			{ data.birthDate && (
				<p>
					Birth Date:
					{' '}
					{formatDate(data.birthDate)}
				</p>
			)}
			{data.profession && (
				<p>
					Profession:
					{' '}
					{data.profession}
				</p>
			)}
			{data.about && (
				<p>
					About:
					{' '}
					{data.about}
				</p>
			)}
		</div>
	</Modal>
);

export default AdditionalInfoModal;
