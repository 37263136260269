import { BaseEntity } from './common';

export type PixDTO = BaseEntity & {
	cpf: string | null;
	bankId: number | null;
	agency: string | null;
	accountNumber: string | null;
	email: string | null;
	phone: string | null;
	cnpj: string | null;
	key: PixKey;
	city: string | null;
	stateId: number | undefined;
	countryId: number | undefined;
};

export enum PixKey {
	BANK_ACCOUNT = 'bank account',
	CNPJ = 'cnpj',
	CPF = 'cpf',
	EMAIL = 'email',
	PHONE = 'phone',
}
