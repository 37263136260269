import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AdminsService } from 'services/admins-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	CREATE_ADMIN,
	GET_ADMIN,
	GET_ADMINS,
	LOCK_ADMIN,
	UNLOCK_ADMIN,
	UPDATE_ADMIN,
} from 'utils/constants/end-points';
import { AdminsState } from 'utils/types/store';
import { RequestBody, RequestQuery, UpdateAdminRequestBody } from 'utils/types/request-types';
import { RootState } from './index';

const initialState: AdminsState = {
	adminsList: [],
	status: FetchStatus.IDLE,
};

export const requestAdminsList = createAsyncThunk(
	'admins/list',
	async () => {
		const fetchInstance = new AdminsService(GET_ADMINS);
		const result = await fetchInstance.getAdmins();

		return result;
	},
);

export const requestAdmin = createAsyncThunk(
	'get-admin',
	async ({ id }: RequestQuery) => {
		const fetchInstance = new AdminsService(GET_ADMIN);
		const result = await fetchInstance.getAdmin({ id });

		return result;
	},
);

export const createAdmin = createAsyncThunk(
	'create-admin',
	async (body: RequestBody) => {
		const fetchInstance = new AdminsService(CREATE_ADMIN);

		await fetchInstance.createAdmin(body);
	},
);

export const updateAdmin = createAsyncThunk(
	'update-admin',
	async (reqBody: UpdateAdminRequestBody) => {
		const { query, body } = reqBody;
		const fetchInstance = new AdminsService(UPDATE_ADMIN);

		await fetchInstance.updateAdmin(query, body);
	},
);

export const lockAdmin = createAsyncThunk(
	'lock-admin',
	({ id }: RequestQuery) => {
		const fetchInstance = new AdminsService(LOCK_ADMIN);

		fetchInstance.lock({ id });

		return Number(id);
	},
);

export const unlockAdmin = createAsyncThunk(
	'unlock-admin',
	({ id }: RequestQuery) => {
		const fetchInstance = new AdminsService(UNLOCK_ADMIN);

		fetchInstance.unlock({ id });

		return Number(id);
	},
);

const adminsSlice = createSlice({
	name: 'admins',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestAdminsList.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestAdminsList.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.adminsList = action.payload;
			})
			.addCase(lockAdmin.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.adminsList = state.adminsList.map((admin) => {
					if (admin.id === action.payload) {
						admin.isLocked = true;
					}

					return admin;
				});
			})
			.addCase(unlockAdmin.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.adminsList = state.adminsList.map((admin) => {
					if (admin.id === action.payload) {
						admin.isLocked = false;
					}

					return admin;
				});
			});
	},
});

const getAdmins = (state: RootState) => state.admins;

export const getAdminsList = createSelector(getAdmins, admins => admins.adminsList);

export default adminsSlice.reducer;
