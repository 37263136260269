import { useProtectedImage } from 'utils/hooks/use-protected-image';
import { ImageContainer } from 'utils/types/common';
import { Image } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import { defaultProfilePicture } from 'assets/images';
import './ProtectedImage.scss';

type Props = {
	id: number;
	containerType: ImageContainer;
};

const ProtectedImage = ({ id, containerType }: Props) => {
	if (!id) {
		switch (containerType) {
			case ImageContainer.USER_AVATAR:
				return <Image height={50} preview={false} src={defaultProfilePicture} width={50} />;
			default:
				return <FileImageOutlined style={{ fontSize: '30px' }} />;
		}
	}

	const src = useProtectedImage(id, containerType);

	return (<Image height={50} preview={false} src={src} width={50} />);
};

export { ProtectedImage };
