import { useEffect } from 'react';
import { getGlossaryData, requestBanksGlossary } from 'store/glossaries';
import { Bank, GlossaryEnum } from 'utils/types/glossaries';
import { useAppDispatch, useAppSelector } from './app-hooks';

export const useBanks = (): Bank[] | null => {
	const dispatch = useAppDispatch();
	const banks: Array<Bank> | null =
		useAppSelector(getGlossaryData(GlossaryEnum.BANKS));

	useEffect(() => {
		if (!banks) dispatch(requestBanksGlossary());
	}, [banks, dispatch]);

	return banks;
};
