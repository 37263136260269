import { Navigate } from 'react-router-dom';
import { Button } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { InputPassword, InputText } from 'components/inputs-formik';
import { Navbar } from 'components/navbar';
import { getLoggedIn, loginAsync } from 'store/auth';
import { Credentials } from 'utils/types/auth';
import { credentialsValidationSchema } from 'utils/validation';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './Login.scss';

const Login = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const loggedIn = useAppSelector(getLoggedIn);

	type InitialValues = {
		username: string;
		password: string;
	};

	const initialValues: InitialValues = {
		username: '',
		password: '',
	};

	const InnerForm = ({ dirty, isValid }: FormikProps<Credentials>): JSX.Element => (
		<Form className="login">
			<Navbar menu />

			<div className="login__form-wrapper">
				<div className="login__form-body">
					<InputText label="username" name="username" placeholder="username" />
					<InputPassword label="password" name="password" placeholder="password" />
					<div className="login__form-footer flex justify-content-center">
						<Button
							className="btn"
							disabled={!dirty || !isValid}
							htmlType="submit"
							type="primary"
						>
							Login
						</Button>
					</div>
				</div>
			</div>
		</Form>
	);

	const handleSubmit = (credentials: Credentials): void => {
		dispatch(loginAsync(credentials));
	};

	return loggedIn ? <Navigate to="/" /> : (
		<Formik
			component={InnerForm}
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={credentialsValidationSchema}
		/>
	);
};

export { Login };
