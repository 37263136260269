import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './LoadingSpinner.scss';

const LoadingSpinner = (): JSX.Element => (
	<div className="loading-spinner">
		<Spin indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
	</div>
);

export { LoadingSpinner };
