// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';
import { Dayjs } from 'dayjs';
import { DatePicker as AntdDatePicker } from 'components/dayjs-datepickers';
import { DD_MM_YYYY } from 'utils/constants/date-time';
import './DateRangePicker.scss';

const { RangePicker: AntdRangePicker } = AntdDatePicker;

type Props = {
	onChange: (values: RangeValue<Dayjs>, formatString: [string, string]) => void;
	onOk: (dates: RangeValue<Dayjs>) => void;
	defaultValue?: RangeValue<Dayjs>;
};

const DateRangePicker = ({ onChange, onOk, defaultValue }: Props): JSX.Element => (
	<div className="daterange-picker">
		<AntdRangePicker
			defaultValue={defaultValue}
			format={DD_MM_YYYY}
			onChange={onChange}
			onOk={onOk}
		/>
	</div>
);

export { DateRangePicker };
