import dayjs from 'dayjs';
import { useProtectedInvoice } from 'utils/hooks/use-protected-invoice';
import { useState } from 'react';
import { Input, Modal, Radio } from 'antd';
import { Button } from 'components';
import { UpdateInvoiceRequest, WithdrawalInfo } from 'utils/types/invoices';
import { useAppDispatch } from 'utils/hooks';
import { updateInvoice } from 'store/invoices';

export const columns = [
	{
		title: 'Date',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		render: (date: string) => dayjs(date).format('DD/MM/YYYY HH:mm:ss'),
	},
	{
		title: 'Invoice',
		dataIndex: 'invoiceId',
		key: 'invoiceId',
		render: (invoiceId: number): JSX.Element | null => {
			if (!invoiceId) {
				return null;
			}

			return (
				<a href={useProtectedInvoice(invoiceId)} target="_blank">Click to View</a>
			);
		},
	},
	{
		title: 'Actual Request Balance, R$',
		dataIndex: 'actualRequestBalance',
		key: 'actualRequestBalance',
	},
	{
		title: 'Current Balance, R$',
		dataIndex: 'currentBalance',
		key: 'currentBalance',
	},
	{
		title: 'Comment',
		dataIndex: 'comment',
		key: 'comment',
	},
	{
		title: 'Status',
		dataIndex: 'withdrawalInfo',
		key: 'withdrawalInfo',
		render: (withdrawalInfo: WithdrawalInfo): JSX.Element | null => {
			if (!withdrawalInfo) {
				return null;
			}

			const dispatch = useAppDispatch();
			const { TextArea } = Input;
			const { invoiceId, balance, status, comment } = withdrawalInfo;

			const [isModalVisible, setIsModalVisible] = useState(false);
			const [statusValue, setStatusValue] = useState<string>(status);
			const [commentValue, setCommentValue] = useState<string | null>(comment);

			const handleInvoiceUpdate = () => {
				const req: UpdateInvoiceRequest = {
					query: { id: String(invoiceId) },
					body: {
						status: statusValue,
						comment: commentValue,
					},
				};

				dispatch(updateInvoice(req)).then(() => setIsModalVisible(false));
			};

			return (
				<>
					<button className="btn btn-outline-orange" onClick={() => setIsModalVisible(true)}>
						{status}
					</button>
					<Modal
						footer={[
							<Button key="cancel" onClick={() => setIsModalVisible(false)} type="default">
								Cancel
							</Button>,
							<Button key="submit" onClick={handleInvoiceUpdate} type="primary">
								Submit
							</Button>,
						]}
						onCancel={() => setIsModalVisible(false)}
						onOk={() => setIsModalVisible(false)}
						title="Withdrawal Status"
						visible={isModalVisible}
					>
						<p>
							Invoice:
							{' '}
							<a href={useProtectedInvoice(invoiceId)} target="_blank">Click to View</a>
						</p>
						<p>
							Current Balance, R$:
							{' '}
							{balance}
						</p>
						<Radio.Group
							buttonStyle="solid"
							defaultValue={statusValue}
							name="status"
							onChange={(e) => setStatusValue(e.target.value as string)}
						>
							<Radio.Button value="Analysis">Analysis</Radio.Button>
							<Radio.Button value="Accepted">Accepted</Radio.Button>
							<Radio.Button value="Rejected">Rejected</Radio.Button>
						</Radio.Group>
						<TextArea
							defaultValue={commentValue ?? ''}
							name="comment"
							onChange={(e) => setCommentValue(e.target.value)}
							placeholder="Leave a comment..."
							rows={3}
						/>
					</Modal>
				</>
			);
		},
	},
];
