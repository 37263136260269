import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Row } from 'antd';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Button, Checkbox, InputText, ProfileDropdown, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { makeQuery } from 'utils/helpers';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { arrowDownPurple, searchIcoWhite } from 'assets/images';
import { DocumentsListDTO, DocumentsList } from 'utils/types/documents';
import { requestDocuments, selectDocuments } from 'store/documents';
import { columns } from './DocumentsColumns';
import './Documents.scss';

const Documents = (): JSX.Element => {
	const { Option } = Select;

	const dispatch = useAppDispatch();
	const documentsList = useAppSelector<DocumentsListDTO>(selectDocuments);

	type FormValues = {
		orderBy: string;
		sorting: string;
		search: string;
		verifiedStatus: boolean;
		processingStatus: boolean;
		notVerifiedStatus: boolean;
		notLoaded: boolean;
	};

	const initialValues: FormValues = {
		orderBy: 'created',
		sorting: 'ASC',
		search: '',
		verifiedStatus: true,
		processingStatus: true,
		notVerifiedStatus: true,
		notLoaded: true,
	};

	const normalize = (coll: DocumentsListDTO): DocumentsList => coll.map(({
		userId,
		updatedAt,
		name,
		email,
		phone,
		documentId,
		status,
	}) => ({
		id: documentId,
		userId,
		date: dayjs(updatedAt).format('DD/MM/YYYY HH:mm:ss'),
		name,
		phone,
		email,
		document: {
			id: documentId,
			status: status,
		},
	}));

	useEffect(() => {
		dispatch(requestDocuments(makeQuery(initialValues)));
	}, [dispatch]);

	const normalizedDocuments: DocumentsList = normalize(documentsList);

	const InnerForm = (): JSX.Element => (
		<Form>
			<div className="documents__filter">
				<div className="documents__filter-search">
					<InputText name="search" placeholder="Enter search query" />
					<div className="documents__filter-search-btn">
						<img alt="" src={searchIcoWhite} />
					</div>
				</div>
				<Select
					defaultValue="ASC"
					name="sorting"
					suffixIcon={<img alt="" src={arrowDownPurple} />}
				>
					<Option value="ASC">Ascending</Option>
					<Option value="DESC">Descending</Option>
				</Select>
				<Select
					defaultValue="created"
					name="orderBy"
					suffixIcon={<img alt="" src={arrowDownPurple} />}
				>
					<Option value="created">Created</Option>
					<Option value="alphabet">Alphabetically</Option>
				</Select>
			</div>
			<Row justify="start">
				<div className="documents__filter">
					<Checkbox label="Verified:" name="verifiedStatus" />
					<Checkbox label="Processing:" name="processingStatus" />
					<Checkbox label="Not verified:" name="notVerifiedStatus" />
					{/* <Checkbox label="Not loaded:" name="notLoaded" /> */}
					<Button htmlType={HTMLButtonTypes.SUBMIT} text="Submit" />
				</div>
			</Row>
		</Form>
	);

	const handleSubmit = (values: FormValues) => {
		dispatch(requestDocuments(makeQuery(values)));
	};

	return (
		<div className="documents">
			<div className="header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="container-item">
					<div className="documents__top">
						<div className="container-item__header">
							<h2 className="container-item__header-text">Documents</h2>
						</div>

						<div className="documents__filter">
							<Formik
								component={InnerForm}
								initialValues={initialValues}
								onSubmit={handleSubmit}
							/>
						</div>
					</div>

					<div className="container-item__body">
						<Table
							columns={columns}
							data={normalizedDocuments}
							rowKey="id"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Documents };
