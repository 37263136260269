import { Routes, Route } from 'react-router-dom';
import { Trainings } from 'views/content/trainings';
import { AddTraining } from 'views/content/trainings/add-training';
import { EditTraining } from 'views/content/trainings/manage-trainings/edit-training';
import { ManageCategories } from 'views/content/trainings/manage-categories';
import { ManageTrainings } from 'views/content/trainings/manage-trainings';
import { EditTrainingCategory } from 'views/content/trainings/manage-categories/edit-training-category';
import { NotFound } from 'views/not-found';
import { AddTrainingCategory } from './manage-categories/add-training-category';

const TrainingRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Trainings />} index />
		<Route element={<AddTraining />} path="add-training" />
		<Route element={<ManageTrainings />} path="manage-trainings" />
		<Route element={<EditTraining />} path="manage-trainings/edit/:id" />
		<Route element={<ManageCategories />} path="manage-categories" />
		<Route element={<AddTrainingCategory />} path="manage-categories/add-category" />
		<Route element={<EditTrainingCategory />} path="manage-categories/edit/:id" />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { TrainingRoutes };
