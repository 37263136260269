import { Menu, Dropdown, Image } from 'antd';
import { Button } from 'components/button';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { signOut } from 'store/auth';
import { defaultProfilePicture } from 'assets/images';
import { useEffect } from 'react';
import { getAdminName, requestAdminName } from 'store/admin-profile';
import './ProfileDropdown.scss';

const ProfileDropdown = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const adminName = useAppSelector<string>(getAdminName);

	useEffect(() => {
		dispatch(requestAdminName());
	}, [dispatch]);

	const menu: JSX.Element = (
		<Menu className="profile-dropdown__menu">
			<Menu.Item key={1}>
				<Button
					className="btn btn-orange logout-btn"
					onClick={() => dispatch(signOut())}
					text="Logout"
					type="text"
				/>
			</Menu.Item>
		</Menu>
	);

	const ProfilePicture = () => (
		<Image
			className="profile-picture"
			height={35}
			preview={false}
			src={defaultProfilePicture}
			width={45}
		/>
	);

	return (
		<Dropdown overlay={menu} placement="bottomCenter">
			<div className="profile-dropdown center">
				<ProfilePicture />
				<span className="profile-dropdown__label">
					{adminName}
				</span>
			</div>
		</Dropdown>
	);
};

export { ProfileDropdown };
