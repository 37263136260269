export const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Phone',
		dataIndex: 'phoneNumber',
		key: 'phoneNumber',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		render: (status: string) => (
			<p className="leads-status">
				{status}
			</p>
		),
	},
	{
		title: 'Comment',
		dataIndex: 'comment',
		key: 'comment',
	},
	{
		title: 'User Name',
		dataIndex: 'userName',
		key: 'userName',
	},
];
