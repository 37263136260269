import { Routes, Route } from 'react-router-dom';
import { ComplaintsList } from 'views/complaints/ComplaintsList';
import { NotFound } from 'views/not-found';

const ComplaintsRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<ComplaintsList />} index />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { ComplaintsRoutes };
