import { useState, useEffect } from 'react';
import { getAccessToken } from 'store/auth';
import { BASE_API_URL } from 'utils/constants/base';
import { DOWNLOAD_DOCUMENT } from 'utils/constants/end-points';
import { useAppSelector } from 'utils/hooks';
import { makeAuthHeader } from 'utils/helpers';
import { RequestMethods } from 'utils/enums/request-methods';

export const useProtectedDocument = (id: number): string => {
	const token: string | null = useAppSelector(getAccessToken);
	const authorizationHeader: Record<string, string> = makeAuthHeader(token as string);

	const [src, setSrc] = useState<string>('');

	useEffect(() => {
		let mounted = true;

		if (token) {
			const path =
				`${BASE_API_URL as string}/${DOWNLOAD_DOCUMENT}?documentId=${id}`;

			const options = {
				method: RequestMethods.GET,
				headers: authorizationHeader,
			};

			fetch(path, options)
				.then(res => res.blob())
				.then(blob => mounted && setSrc(URL.createObjectURL(blob)));
		}

		return () => {
			mounted = false;
		};

	}, [id, token]);

	return src;
};
