import { ToastContainer } from 'react-toastify';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { MainSider } from 'views/main-sider';
import { Dashboard } from 'views/dashboard';
import { Login } from 'views/login';
import { AdminsRoutes } from 'views/admins/AdminsRoutes';
import { UsersRoutes } from 'views/users/UsersRoutes';
import { ContentRoutes } from 'views/content/ContentRoutes';
import { FinancialRoutes } from 'views/financial/FinancialRoutes';
import { StatisticsRoutes } from 'views/statistics/StatisticsRoutes';
import { ComplaintsRoutes } from 'views/complaints/ComplaintsRoutes';
import { SettingsRoutes } from 'views/settings/SettingsRoutes';
import { DocumentsRoutes } from 'views/documents/DocumentsRoutes';
import { NotFound } from 'views/not-found';
import { useAppSelector } from 'utils/hooks';
import { getLoggedIn } from 'store/auth';
import 'utils/fetch/fetch-persist';
import 'utils/helpers/fix-console-warning';
import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';

const AuthRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Login />} index />
		<Route element={<Login />} path="login" />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

const MainRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<MainSider />} path="/">
			<Route element={<Dashboard />} index />
			<Route element={<AdminsRoutes />} path="admins/*" />
			<Route element={<UsersRoutes />} path="users/*" />
			<Route element={<DocumentsRoutes />} path="documents/*" />
			<Route element={<FinancialRoutes />} path="financial/*" />
			<Route element={<ContentRoutes />} path="content/*" />
			<Route element={<StatisticsRoutes />} path="statistics/*" />
			<Route element={<ComplaintsRoutes />} path="complaints/*" />
			<Route element={<SettingsRoutes />} path="settings/*" />
			<Route element={<FinancialRoutes />} path="financial/*" />

			<Route element={<NotFound />} path="*" />
		</Route>
	</Routes>
);

type Props = {
	redirectTo?: string;
};

const RequireAuth = ({ children, redirectTo = '/auth/login' }: PropsWithChildren<Props>): JSX.Element => {
	const loggedIn: boolean = useAppSelector(getLoggedIn);

	return loggedIn ? children as JSX.Element : <Navigate to={redirectTo} />;
};

const ProtectedRoutes = (): JSX.Element => (
	<RequireAuth>
		<ToastContainer
			autoClose={5000}
			hideProgressBar={false}
			pauseOnFocusLoss
			position="top-right"
			theme="colored"
		/>
		<MainRoutes />
	</RequireAuth>
);

const App = (): JSX.Element => (
	<div className="app">
		<Routes>
			<Route element={<AuthRoutes />} path="/auth/*" />
			<Route element={<ProtectedRoutes />} path="/*" />
		</Routes>
	</div>
);

export { App };
