import { useEffect } from 'react';
import { getGlossaryData, requestCountriesGlossary } from 'store/glossaries';
import { Country, GlossaryEnum } from 'utils/types/glossaries';
import { useAppDispatch, useAppSelector } from './app-hooks';

export const useAddress = () => {
	const dispatch = useAppDispatch();
	const countries: Array<Country> | null = useAppSelector(getGlossaryData(GlossaryEnum.COUNTRIES));

	useEffect(() => {
		if (!countries) dispatch(requestCountriesGlossary());
	}, [dispatch]);

	return countries;
};
