// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { BackButton, ProfileDropdown, DateRangePicker, SearchPanel } from 'components';
import { getSalesData, getSalesFilter, requestSales, setSalesListFilter } from 'store/sales';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { SalesDTO, SalesFilter } from 'utils/types/sales';
import SalesTable from './sales-table';
import './Sales.scss';

type DatePickerValue = RangeValue<dayjs.Dayjs>;

const Sales = () => {
	const dispatch = useAppDispatch();
	const salesList = useAppSelector<SalesDTO>(getSalesData);
	const salesFilter = useAppSelector<SalesFilter>(getSalesFilter);

	const [dateRange, setDateRange] = useState<DatePickerValue>([dayjs().subtract(7, 'days'), dayjs()]);

	useEffect(() => {
		if (!dateRange) return;
		const [startDate, endDate] = dateRange;

		if (startDate && endDate) {
			dispatch(requestSales({
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
				...salesFilter,
			}));
		}
	}, [dispatch, dateRange, salesFilter]);

	const handleSearch = (search: string): void => {
		dispatch(setSalesListFilter({ ...salesFilter, search }));
	};

	const handleSubmit = (values: DatePickerValue): void => {
		if (values !== null) {
			const [startDate, endDate] = values;

			if (startDate !== null && endDate !== null) {
				setDateRange(values);
			}
		}
	};

	return (
		<div className="sales-list">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="sales-list__top">
					<div className="page-title">
						<BackButton />
						<h1>Sales list</h1>
					</div>

					<div className="sales-filters">
						<SearchPanel initialValue={salesFilter.search} onSearch={handleSearch} />

						<DateRangePicker
							defaultValue={dateRange}
							onChange={handleSubmit}
							onOk={() => undefined}
						/>
					</div>
				</div>

				<SalesTable dataSource={salesList} />
			</div>
		</div>
	);
};

export { Sales };
