import { BackButton, Button, ProfileDropdown } from 'components';
import { Link } from 'react-router-dom';
import { trainingsIco, mediaIco } from 'assets/images';
import './Content.scss';

const Content = (): JSX.Element => (
	<div className="manage-content">
		<div className="row header">
			<ProfileDropdown />
		</div>

		<div className="container">
			<div className="page-title">
				<BackButton />
				<h1>Manage content</h1>
			</div>
			<div className="manage-content__wrap">
				<Link to="media">
					<Button className="btn-purple" icon={<img alt="" src={mediaIco} />} text="Media" />
				</Link>
				<Link to="trainings">
					<Button className="btn-purple" icon={<img alt="" src={trainingsIco} />} text="Trainings" />
				</Link>
			</div>
		</div>
	</div>
);

export { Content };
