import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { checkRoundGreen, crossRoundRed } from 'assets/images';
import { Button, ProtectedImage } from 'components';
import { unlockUser, lockUser } from 'store/users';
import convertBalance from 'utils/helpers/convert-balance';
import { useAppDispatch } from 'utils/hooks';
import { ImageContainer } from 'utils/types/common';
import { UserDTO, UsersListDTO } from 'utils/types/users';
import UserAdditionalInfo from './user-additional-info';
import './UsersTable.scss';

type Props = {
	dataSource: UsersListDTO;
};

const UsersTable = ({ dataSource }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const columns: ColumnsType<UserDTO> = [
		{
			title: '',
			dataIndex: 'avatarImageId',
			key: 'avatarImageId',
			render: (avatarImageId: number) =>
				(<ProtectedImage containerType={ImageContainer.USER_AVATAR} id={avatarImageId} />),
			width: 80,
		},
		{
			title: 'Name',
			dataIndex: 'fullName',
			key: 'fullName',
			width: 250,
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			key: 'balance',
			render: (balance: number) => (
				<div>
					{convertBalance(balance)}
				</div>
			),
			width: 80,
		},
		{
			title: 'CPF',
			dataIndex: 'cpf',
			key: 'cpf',
			width: 200,
		},
		{
			title: 'Invoicing Permissions',
			dataIndex: 'invoicingPermissions',
			key: 'invoicingPermissions',
			render: (hasPermission: boolean) => hasPermission ? '✓' : '✕',
			width: 150,
		},
		{
			title: 'User Information',
			dataIndex: 'birthDate',
			key: 'birthDate',
			render: (_, record) => <UserAdditionalInfo user={record} />,
			width: 550,
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			key: 'actions',
			width: 200,
			render: (_, record) => {
				const { id, isLocked } = record;

				return (
					<div>
						{isLocked ? (
							<Button
								className="btn-lock"
								icon={<img alt="" src={checkRoundGreen} />}
								onClick={() => dispatch(unlockUser({ id: id.toString() }))}
								text="Unlock"
							/>
						) : (
							<Button
								className="btn-lock"
								icon={<img alt="" src={crossRoundRed} />}
								onClick={() => dispatch(lockUser({ id: id.toString() }))}
								text="Lock"
							/>
						)}
					</div>
				);
			},
		},
	];

	return (
		<Table
			className="users-table"
			columns={columns}
			dataSource={dataSource}
			rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default UsersTable;
