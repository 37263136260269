import { BackButton, Button, ProfileDropdown } from 'components';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form, Select } from 'formik-antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import {
	updateTraining,
	requestTraining,
} from 'store/trainings';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { UserLevels } from 'utils/enums/user-level';
import { userLevels } from 'utils/constants/user-levels';
import { editTrainingValidationSchema } from 'utils/validation/schemes';
import { InputText } from 'components/inputs-formik';
import { useNavigate, useParams } from 'react-router-dom';
import { getTrainingCategoriesList, requestTrainingCategories } from 'store/training-categories';
import { toast } from 'react-toastify';
import { arrowDownPurple } from 'assets/images';
import './EditTraining.scss';

const EditTraining = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id } = useParams<{ id?: string }>();
	const trainingCategories = useAppSelector(getTrainingCategoriesList);

	type FormValues = {
		title?: string;
		description?: string;
		categoryId?: number;
		level: UserLevels;
	};

	const [initialValues, setInitialValues] = useState<FormValues>({
		level: 0,
	});

	useEffect(() => {
		if (id) {
			dispatch(requestTrainingCategories());
			dispatch(requestTraining({ id })).then(({ payload }) => setInitialValues(payload as FormValues));
		}
	}, [dispatch]);

	const handleSubmit = async ({ categoryId, level, title, description }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {
		if (categoryId && !isNaN(level) && id && title && description) {
			const body = {
				title,
				description,
				level,
				categoryId,
			};

			try {
				await dispatch(updateTraining({ id: Number(id), body })).unwrap();
				toast.success('Success!');
				navigate('/content/trainings/manage-trainings');
			} catch {
				toast.error('Failed!');
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form className="edit-training__form">
			<InputText name="title" placeholder="Enter training title" />
			<InputText name="description" placeholder="Enter training description" />
			<div className="edit-training__form-footer">
				<div>
					<Select
						className="category"
						name="categoryId"
						placeholder="Category"
						suffixIcon={<img alt="" src={arrowDownPurple} />}
					>
						{trainingCategories.map(({ id, title }) => (
							<Select.Option key={id} value={id}>
								{title}
							</Select.Option>
						))}
					</Select>
					<Select
						className="level"
						name="level"
						placeholder="Level"
						suffixIcon={<img alt="" src={arrowDownPurple} />}
					>
						{Object.entries(userLevels).map(([key, value]) => (
							<Select.Option key={value} value={value}>
								{key}
							</Select.Option>
						))}
					</Select>
				</div>
				<Button
					className="btn-purple"
					disabled={!dirty || !isValid || isSubmitting}
					htmlType={HTMLButtonTypes.SUBMIT}
					text="Submit"
				/>
			</div>
		</Form>
	);

	return (
		<div className="edit-training">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Edit training</h1>
				</div>
				<div className="edit-training__wrap">
					<Formik
						component={InnerForm}
						enableReinitialize={true}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={editTrainingValidationSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { EditTraining };
