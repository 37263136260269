import { BankAccountModal, Button, PixModal } from 'components';
import { useWindow } from 'utils/hooks';
import { InvoiceDTO } from 'utils/types/invoices';
import './PaymentData.scss';

type Props = {
	data: InvoiceDTO;
};

const PaymentData = ({ data }: Props): JSX.Element | null => {
	const [bankAccountModal, showBankAccountModal, hideBankAccountModal] = useWindow();
	const [pixModal, showPixModal, hidePixModal] = useWindow();

	return (
		<div className="payment-data">
			{data.bankAccountId && (
				<Button
					className="btn-outline btn-outline-orange"
					onClick={showBankAccountModal}
					text="Bank account details"
				/>
			)}

			{data.pixId && (
				<Button
					className="btn-outline btn-outline-orange"
					onClick={showPixModal}
					text="PIX details"
				/>
			)}

			{bankAccountModal && data.bankAccountId && (
				<BankAccountModal bankAccountId={data.bankAccountId} onClose={hideBankAccountModal} />
			)}

			{pixModal && data.pixId && (
				<PixModal onClose={hidePixModal} pixId={data.pixId} />
			)}
		</div>
	);
};

export default PaymentData;
