import { Routes, Route } from 'react-router-dom';
import { Documents } from 'views/documents';
import { NotFound } from 'views/not-found';

const DocumentsRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Documents />} index />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { DocumentsRoutes };
