import { Link } from 'react-router-dom';
import { BackButton, Button, ProfileDropdown } from 'components';
import './Financial.scss';

const Financial = (): JSX.Element => (
	<div className="financial-content">
		<div className="row header">
			<ProfileDropdown />
		</div>

		<div className="container">
			<div className="page-title">
				<BackButton />
				<h1>Financial</h1>
			</div>
			<div className="financial-content__wrap">
				<Link to="commissions">
					<Button className="btn-purple" text="Commissions" />
				</Link>
				<Link to="sales">
					<Button className="btn-purple" text="Sales" />
				</Link>
			</div>
		</div>
	</div>
);

export { Financial };
