import { FetchAPI } from 'utils/fetch/fetch-api';
import {
	AdminProfileNameDTO,
} from 'utils/types/admin-profile';

class AdminProfileService extends FetchAPI {
	name = (): Promise<AdminProfileNameDTO> => this.get<AdminProfileNameDTO>();
}

export { AdminProfileService };
