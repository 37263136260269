import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersService } from 'services/users-service';
import { USERS_FILTER_DEFAULT } from 'utils/constants/default-filters';
import {
	GET_PENDING_USERS,
	APPROVE_PENDING_USER,
	REJECT_PENDING_USER,
	GET_USERS,
	LOCK_PENDING_USER,
	UNLOCK_PENDING_USER,
} from 'utils/constants/end-points';
import { FetchStatus } from 'utils/enums/fetch-status';
import { RequestQuery } from 'utils/types/request-types';
import { UsersState } from 'utils/types/store';
import { UsersFilter } from 'utils/types/users';
import { RootState } from './index';

const initialState: UsersState = {
	pendingUsersList: [],
	usersList: [],
	status: FetchStatus.IDLE,
	filter: USERS_FILTER_DEFAULT,
};

export const requestUsersList = createAsyncThunk(
	'users/list',
	async (query: RequestQuery) => {
		const fetchInstance = new UsersService(GET_USERS);
		const result = await fetchInstance.usersList(query);

		return result;
	},
);

export const lockUser = createAsyncThunk(
	'users/lock',
	({ id }: RequestQuery) => {
		const fetchInstance = new UsersService(LOCK_PENDING_USER);

		fetchInstance.lock({ id });

		return Number(id);
	},
);

export const unlockUser = createAsyncThunk(
	'users/unlock',
	({ id }: RequestQuery) => {
		const fetchInstance = new UsersService(UNLOCK_PENDING_USER);

		fetchInstance.unlock({ id });

		return Number(id);
	},
);

export const requestPendingUsersList = createAsyncThunk(
	'pending-users/list',
	async () => {
		const fetchInstance = new UsersService(GET_PENDING_USERS);
		const result = await fetchInstance.pendingUsersList();

		return result;
	},
);

export const approvePendingUser = createAsyncThunk(
	'pending-users/approve',
	({ id }: RequestQuery) => {
		const fetchInstance = new UsersService(APPROVE_PENDING_USER);

		fetchInstance.approve({ id });

		return Number(id);
	},
);

export const rejectPendingUser = createAsyncThunk(
	'pending-users/reject',
	({ id }: RequestQuery) => {
		const fetchInstance = new UsersService(REJECT_PENDING_USER);

		fetchInstance.approve({ id });

		return Number(id);
	},
);

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUsersFilter: (state, { payload }: PayloadAction<UsersFilter>) => {
			state.filter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestUsersList.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestPendingUsersList.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(lockUser.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(unlockUser.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(approvePendingUser.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(rejectPendingUser.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestUsersList.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.usersList = action.payload;
			})
			.addCase(requestPendingUsersList.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.pendingUsersList = action.payload;
			})
			.addCase(lockUser.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.usersList = state.usersList.map((user) => {
					if (user.id === action.payload) {
						user.isLocked = true;
					}

					return user;
				});
			})
			.addCase(unlockUser.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.usersList = state.usersList.map((user) => {
					if (user.id === action.payload) {
						user.isLocked = false;
					}

					return user;
				});
			})
			.addCase(approvePendingUser.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.pendingUsersList = state.pendingUsersList.filter(item => item.id !== action.payload);
			})
			.addCase(rejectPendingUser.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.pendingUsersList = state.pendingUsersList.filter(item => item.id !== action.payload);
			});
	},
});

export const { setUsersFilter } = usersSlice.actions;

const getUsers = (state: RootState) => state.users;

export const getUsersList = createSelector(getUsers, users => users.usersList);
export const getUsersFilter = createSelector(getUsers, users => users.filter);

export const getPendingUsersList = createSelector(getUsers, users => users.pendingUsersList);

export default usersSlice.reducer;
