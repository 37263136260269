import { Routes, Route } from 'react-router-dom';
import { Commissions } from 'views/financial/commissions';
import { NotFound } from 'views/not-found';
import { CommissionsHistory } from './commissions-history';

const CommissionsRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Commissions />} index />
		<Route element={<CommissionsHistory />} path="commissions-history/:userId" />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { CommissionsRoutes };
