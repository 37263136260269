import { Col, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { ProfileDropdown, Chart } from 'components';
import {
	getNewRegistrationRequestsAmount, getNewSalesAmount, getNewUsersAmount,
	requestNewRegistrationRequestsAmount, requestNewSalesAmount, requestNewUsersAmount,
} from 'store/statistics';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { NewRegistrationRequestsAmountDTO, NewSalesAmountDTO, NewUsersAmountDTO } from 'utils/types/statistics';
import { ChartModes } from 'utils/enums/chart-modes';
import './Statistics.scss';

const Statistics = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const newSalesAmount = useAppSelector<NewSalesAmountDTO>(getNewSalesAmount);
	const newUsersAmount = useAppSelector<NewUsersAmountDTO>(getNewUsersAmount);
	const newRegistrationRequestsAmount =
		(useAppSelector<NewRegistrationRequestsAmountDTO>(getNewRegistrationRequestsAmount));
	const [chartMode, setChartMode] = useState(ChartModes.WEEK);

	useEffect(() => {
		dispatch(requestNewSalesAmount());
		dispatch(requestNewUsersAmount());
		dispatch(requestNewRegistrationRequestsAmount());
	}, [dispatch]);

	const labels = {
		week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
		month: ['1', '2', '3', '4', '5',
			'6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16',
			'17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '28', '29', '30', '31'],
		year: ['January', 'February', 'March', 'April',
			'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	};

	return (
		<div className="statistics">
			<div className="header">
				<ProfileDropdown />
			</div>

			<div className="statistics__filters">
				<Row justify="start">
					<Col span={5}>
						<h3>Chart Mode:</h3>
					</Col>
					<Col>
						<Select
							defaultValue={ChartModes.WEEK}
							onChange={(e) => setChartMode(e)}
							placeholder="Chart Mode"
						>
							{Object.entries(ChartModes).map(([key, value]) => (
								<Select.Option key={key} value={value}>
									{key}
								</Select.Option>
							))}
						</Select>
					</Col>
				</Row>
			</div>
			<div className="statistics__wrap">
				<div className="statistics__items">
					<div className="statistics__item">
						<div className="statistics__item-title">
							New Sales Chart
						</div>
						<div className="statistics__item-text">
							<Chart
								data={newSalesAmount[chartMode]}
								labels={labels[chartMode]}
							/>
						</div>
					</div>
					<div className="statistics__item">
						<div className="statistics__item-title">
							New Users Chart
						</div>
						<div className="statistics__item-text">
							<Chart
								data={newUsersAmount[chartMode]}
								labels={labels[chartMode]}
							/>
						</div>
					</div>
					<div className="statistics__item">
						<div className="statistics__item-title">
							New Registration Requests Chart
						</div>
						<div className="statistics__item-text">
							<Chart
								data={newRegistrationRequestsAmount[chartMode]}
								labels={labels[chartMode]}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Statistics };
