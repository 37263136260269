import { FetchAPI } from 'utils/fetch/fetch-api';
import {
	GroupsListDTO,
} from 'utils/types/groups';

class GroupsService extends FetchAPI {
	groupsList = (): Promise<GroupsListDTO> => this.get<GroupsListDTO>();
}

export { GroupsService };
