import { Routes, Route } from 'react-router-dom';
import { AdminsList } from 'views/admins/AdminsList';
import { AddAdmin } from 'views/admins/add-admin';
import { NotFound } from 'views/not-found';
import { EditAdmin } from 'views/admins/edit-admin';

const AdminsRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<AdminsList />} index />
		<Route element={<AddAdmin />} path="add-admin" />
		<Route element={<EditAdmin />} path="edit/:id" />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { AdminsRoutes };
