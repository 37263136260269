import { BackButton, Button, ProfileDropdown } from 'components';
import { Link } from 'react-router-dom';
import { manageMediaIco, addMediaIco } from 'assets/images';
import './Media.scss';

const Media = (): JSX.Element => (
	<div className="media">
		<div className="row header">
			<ProfileDropdown />
		</div>

		<div className="container">
			<div className="page-title">
				<BackButton />
				<h1>Media</h1>
			</div>
			<div className="media__wrap">
				<Link to="add-media">
					<Button className="btn-purple" icon={<img alt="" src={addMediaIco} />} text="Add media" />
				</Link>
				<Link to="manage-media">
					<Button className="btn-purple" icon={<img alt="" src={manageMediaIco} />} text="Manage media" />
				</Link>
			</div>
		</div>
	</div>
);

export { Media };
