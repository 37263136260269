import { FetchAPI } from 'utils/fetch/fetch-api';
import {
	MetricDTO,
} from 'utils/types/dashboard';

class DashboardService extends FetchAPI {
	salesAmountToday = (): Promise<MetricDTO> => this.get<MetricDTO>();

	usersAmountToday = (): Promise<MetricDTO> => this.get<MetricDTO>();

	pendingRegistrationsAmountToday = (): Promise<MetricDTO> => this.get<MetricDTO>();

	groupsAmountToday = (): Promise<MetricDTO> => this.get<MetricDTO>();

	commissionAmountToday = (): Promise<MetricDTO> => this.get<MetricDTO>();

	usersRegisteredTotal = (): Promise<MetricDTO> => this.get<MetricDTO>();
}

export { DashboardService };
