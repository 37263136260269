import { FetchAPI } from 'utils/fetch/fetch-api';
import { AuthorizationResponse, Credentials } from 'utils/types/auth';
import { AUTH } from 'utils/constants/end-points';

class AuthorizationService extends FetchAPI {
	constructor() {
		super(AUTH);
	}

	authorization = (credentials: Credentials): Promise<AuthorizationResponse> =>
		(this.post<AuthorizationResponse>(credentials));
}

export { AuthorizationService };
