import { FetchAPI } from 'utils/fetch/fetch-api';
import { RequestBody, RequestQuery } from 'utils/types/request-types';
import { CreateMediaDTO, DeleteMediaDTO, MediaDTO, MediaListDTO } from 'utils/types/media';

class MediaService extends FetchAPI {
	getMediaList = (): Promise<MediaListDTO> => this.get<MediaListDTO>();

	getMedia = (query: RequestQuery): Promise<MediaDTO> => this.get<MediaDTO>(query);

	createMedia = (body: RequestBody): Promise<CreateMediaDTO> =>
		this.post<CreateMediaDTO>(body);

	updateMedia = (query: RequestQuery, body: RequestBody): Promise<CreateMediaDTO> =>
		this.put<CreateMediaDTO>(query, body);

	deleteMedia = (query: RequestQuery): Promise<DeleteMediaDTO> =>
		this.delete<DeleteMediaDTO>(query);
}

export { MediaService };
