import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { requestPix } from 'store/pix';
import { BRAZIL_ID } from 'utils/constants/countries';
import { makeQuery } from 'utils/helpers';
import getPixKeyText from 'utils/helpers/get-pix-key-text';
import { useAddress, useAppDispatch, useBanks } from 'utils/hooks';
import { IdType } from 'utils/types/common';
import { Bank, Country } from 'utils/types/glossaries';
import { PixDTO, PixKey } from 'utils/types/pix';

type Props = {
	pixId: IdType;
	onClose: () => void;
};

const PixModal = ({ pixId, onClose }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const [pixData, setPixData] = useState<PixDTO | null>(null);

	const banks: Bank[] | null = useBanks();
	const countries: Country[] | null = useAddress();
	const Brazil: Country | undefined = countries?.find(item => item.id === BRAZIL_ID);

	useEffect(() => {
		if (!pixData) {
			dispatch(requestPix(makeQuery({ id: pixId }))).then(res => {
				setPixData(res.payload as PixDTO);
			});
		}
	}, [dispatch, pixData, pixId]);

	if (!pixData || !banks || !countries || !Brazil) return null;

	return (
		<Modal
			centered
			footer={null}
			onCancel={onClose}
			title="PIX details"
			visible
		>
			<div>
				<p>
					PIX key:
					{' '}
					{getPixKeyText(pixData.key)}
				</p>

				{pixData.key === PixKey.BANK_ACCOUNT && (
					<>
						<p>
							Bank:
							{' '}
							{banks.find(item => item.id === pixData.bankId)?.name ?? 'No data'}
						</p>
						<p>
							Agency:
							{' '}
							{pixData.agency}
						</p>
						<p>
							Account number:
							{' '}
							{pixData.accountNumber}
						</p>
						<p>
							State:
							{' '}
							{Brazil.states.find(item => item.id === pixData.stateId)?.name ?? 'No data'}
						</p>
						<p>
							City:
							{' '}
							{pixData.city}
						</p>
					</>
				)}

				{pixData.key === PixKey.CNPJ && (
					<p>
						CNPJ:
						{' '}
						{pixData.cnpj}
					</p>
				)}

				{pixData.key === PixKey.CPF && (
					<p>
						CPF:
						{' '}
						{pixData.cpf}
					</p>
				)}

				{pixData.key === PixKey.EMAIL && (
					<p>
						Email:
						{' '}
						{pixData.email}
					</p>
				)}

				{pixData.key === PixKey.PHONE && (
					<p>
						Phone number:
						{' '}
						{pixData.phone}
					</p>
				)}
			</div>
		</Modal>
	);
};

export default PixModal;
