import { Fetch } from 'utils/fetch';
import { BASE_API_URL } from 'utils/constants/base';

export class UploadAPI extends Fetch {
	constructor(path: string, headers?: Record<string, string>) {
		super(`${BASE_API_URL as string}/${path}`, {
			...headers,
		});
	}
}
