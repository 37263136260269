import { Modal } from 'antd';
import { Button, ProtectedAttachment } from 'components';
import { useState } from 'react';
import { useProtectedAttachment } from 'utils/hooks';
import { Company } from 'utils/types/users';

export const columns = [
	{
		title: 'Applied',
		dataIndex: 'applied',
		key: 'applied',
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Phone',
		dataIndex: 'phone',
		key: 'phone',
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
	},
	{
		title: 'Refer',
		dataIndex: 'refer',
		key: 'refer',
	},
	{
		title: 'Company',
		dataIndex: 'company',
		key: 'company',
		render: (company: Company): JSX.Element | null => {
			if (!company) {
				return null;
			}

			const [isModalVisible, setIsModalVisible] = useState(false);
			const { name, cnpj, address, email, attachmentId } = company;

			return (
				<>
					<button onClick={() => setIsModalVisible(true)}>
						{name}
					</button>
					<Modal
						footer={[
							<Button key="submit" onClick={() => setIsModalVisible(false)} type="primary">
								OK
							</Button>,
						]}
						onCancel={() => setIsModalVisible(false)}
						onOk={() => setIsModalVisible(false)}
						title="Company Info"
						visible={isModalVisible}
					>
						<h2>
							{name}
						</h2>
						<p>
							cnpj:
							{' '}
							{cnpj}
						</p>
						<p>
							address:
							{' '}
							{address}
						</p>
						<p>
							email:
							{' '}
							{email}
						</p>
						<ProtectedAttachment id={attachmentId} />
						<a href={useProtectedAttachment(attachmentId)} target="_blank">Download Attachment</a>
					</Modal>
				</>
			);
		},
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
	},
];
