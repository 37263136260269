import { Input } from 'antd';
import { searchIcoWhite } from 'assets/images';
import { ChangeEvent, useState } from 'react';
import './SearchPanel.scss';

type Props = {
	initialValue: string;
	placeholder?: string;
	onSearch: (value: string) => void;
};

const SearchPanel = ({ initialValue, placeholder = 'Search...', onSearch }: Props): JSX.Element | null => {
	const [inputValue, setInputValue] = useState<string>(initialValue);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;

		setInputValue(val);

		if (!val) {
			onSearch('');
		}
	};

	const handleSearch = (): void => {
		inputValue && onSearch(inputValue);
	};

	return (
		<div className="search-panel">
			<Input
				className="search-panel__text"
				onChange={handleChange}
				onPressEnter={handleSearch}
				placeholder={placeholder}
				value={inputValue}
			/>

			<button className="search-panel__btn" onClick={handleSearch}>
				<img alt="search-ico" src={searchIcoWhite} />
			</button>
		</div>
	);
};

export default SearchPanel;
