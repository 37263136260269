import { useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { BackButton, Button, Checkbox, ProfileDropdown, SearchPanel } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { InvoicesDTO, InvoicesFilter } from 'utils/types/invoices';
import { requestInvoices, getInvoicesData, getInvoicesFilter, setInvoicesFilter } from 'store/invoices';
import { makeQuery } from 'utils/helpers';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import './Commissions.scss';
import CommissionsTable from './commissions-table';

type FormValues = Pick<InvoicesFilter, 'acceptedStatus' |
'analysisStatus' |
'rejectedStatus'>;

type FilterProps = {
	initialValues: FormValues;
	onSubmit: (values: FormValues) => void;
};

const FiltersForm = (): JSX.Element => (
	<Form className="filters-form">
		<Checkbox label="Analysis:" name="analysisStatus" />
		<Checkbox label="Accepted:" name="acceptedStatus" />
		<Checkbox label="Rejected:" name="rejectedStatus" />
		<Button htmlType={HTMLButtonTypes.SUBMIT} text="Submit" />
	</Form>
);

const InvoicesFilters = ({ initialValues, onSubmit }: FilterProps): JSX.Element => (
	<Formik
		component={FiltersForm}
		initialValues={initialValues}
		onSubmit={onSubmit}
	/>
);

const Commissions = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const invoicesList = useAppSelector<InvoicesDTO>(getInvoicesData);
	const invoicesFilter = useAppSelector<InvoicesFilter>(getInvoicesFilter);

	useEffect(() => {
		dispatch(requestInvoices(makeQuery({ ...invoicesFilter })));
	}, [dispatch, invoicesFilter]);

	const handleSearch = (search: string): void => {
		dispatch(setInvoicesFilter({ ...invoicesFilter, search }));
	};

	const handleSubmit = (values: FormValues) => {
		dispatch(setInvoicesFilter({ ...invoicesFilter, ...values }));
	};

	return (
		<div className="commissions">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="commissions__top">
					<div className="page-title">
						<BackButton />
						<h1>Commissions</h1>
					</div>

					<div className="commissions-filters">
						<SearchPanel initialValue={invoicesFilter.search} onSearch={handleSearch} />

						<InvoicesFilters
							initialValues={{
								acceptedStatus: invoicesFilter.acceptedStatus,
								analysisStatus: invoicesFilter.analysisStatus,
								rejectedStatus: invoicesFilter.rejectedStatus,
							}}
							onSubmit={handleSubmit}
						/>
					</div>
				</div>

				<CommissionsTable dataSource={invoicesList} />
			</div>
		</div>
	);
};

export { Commissions };
