import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { TrainingsService } from 'services/trainings-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	RequestBody, RequestQuery, UpdateTrainingRequestBody,
} from 'utils/types/request-types';
import {
	ASSIGN_TRAINING_CATEGORY,
	CREATE_TRAINING,
	DELETE_TRAINING,
	UPDATE_TRAINING,
	GET_TRAINING,
	GET_TRAININGS,
} from 'utils/constants/end-points';
import { TrainingsState } from 'utils/types/store';
import { RootState } from './index';

const initialState: TrainingsState = {
	trainings: [],
	status: FetchStatus.IDLE,
};

export const requestTrainings = createAsyncThunk(
	'get-trainings-list',
	async (query?: RequestQuery) => {
		const fetchInstance = new TrainingsService(GET_TRAININGS);
		const result = await fetchInstance.getTrainings(query);

		return result;
	},
);

export const requestTraining = createAsyncThunk(
	'get-training',
	async (query?: RequestQuery) => {
		const fetchInstance = new TrainingsService(GET_TRAINING);
		const result = await fetchInstance.getTraining(query);

		return result;
	},
);

export const createTraining = createAsyncThunk(
	'create-training',
	(body: RequestBody) => {
		const fetchInstance = new TrainingsService(CREATE_TRAINING);

		fetchInstance.createTraining(body);

		return;
	},
);

export const assignTrainingCategory = createAsyncThunk(
	'assign-training-category',
	(body: RequestBody) => {
		const fetchInstance = new TrainingsService(ASSIGN_TRAINING_CATEGORY);

		fetchInstance.assignTrainingCategory(undefined, body);

		return;
	},
);

export const deleteTraining = createAsyncThunk(
	'delete-training',
	({ id }: RequestQuery) => {
		const fetchInstance = new TrainingsService(DELETE_TRAINING);

		fetchInstance.deleteTraining({ id });

		return Number(id);
	},
);

export const updateTraining = createAsyncThunk(
	'update-training',
	(reqBody: UpdateTrainingRequestBody) => {
		const { id, body } = reqBody;
		const fetchInstance = new TrainingsService(UPDATE_TRAINING);

		fetchInstance.updateTraining({ id: String(id) }, body);

		return;
	},
);

const trainingsSlice = createSlice({
	name: 'trainings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestTrainings.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(deleteTraining.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestTrainings.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.trainings = action.payload;
			})
			.addCase(deleteTraining.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.trainings = state.trainings.filter(item => item.id !== action.payload);
			});
	},
});

const getTrainings = (state: RootState) => state.trainings;

export const getTrainingsList = createSelector(getTrainings, trainings => trainings.trainings);

export default trainingsSlice.reducer;
