import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { TrainingCategoriesService } from 'services/training-categories-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	CREATE_TRAINING_CATEGORY,
	DELETE_TRAINING_CATEGORY,
	GET_TRAINING_CATEGORIES,
	GET_TRAINING_CATEGORY,
	UPDATE_TRAINING_CATEGORY,
} from 'utils/constants/end-points';
import { TrainingCategoriesState } from 'utils/types/store';
import { RequestBody, RequestQuery, UpdateTrainingCategoryRequestBody } from 'utils/types/request-types';
import { RootState } from './index';

const initialState: TrainingCategoriesState = {
	trainingCategories: [],
	status: FetchStatus.IDLE,
};

export const requestTrainingCategories = createAsyncThunk(
	'training-categories-list',
	async () => {
		const fetchInstance = new TrainingCategoriesService(GET_TRAINING_CATEGORIES);
		const result = await fetchInstance.getTrainingCategories();

		return result;
	},
);

export const requestTrainingCategory = createAsyncThunk(
	'get-training-category',
	async (id: RequestQuery) => {
		const fetchInstance = new TrainingCategoriesService(GET_TRAINING_CATEGORY);
		const result = await fetchInstance.getTrainingCategory(id);

		return result;
	},
);

export const createTrainingCategory = createAsyncThunk(
	'add-training-category',
	async (body: RequestBody) => {
		const fetchInstance = new TrainingCategoriesService(CREATE_TRAINING_CATEGORY);
		const result = await fetchInstance.createTrainingCategory(body);

		return result;
	},
);

export const updateTrainingCategory = createAsyncThunk(
	'update-training-category',
	async (reqBody: UpdateTrainingCategoryRequestBody) => {
		const { query, body } = reqBody;
		const fetchInstance = new TrainingCategoriesService(UPDATE_TRAINING_CATEGORY);
		const result = await fetchInstance.updateTrainingCategory(query, body);

		return result;
	},
);

export const deleteTrainingCategory = createAsyncThunk(
	'delete-training-category',
	({ id }: RequestQuery) => {
		const fetchInstance = new TrainingCategoriesService(DELETE_TRAINING_CATEGORY);

		fetchInstance.deleteTrainingCategory({ id });

		return Number(id);
	},
);

const trainingCategoriesSlice = createSlice({
	name: 'training-categories',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestTrainingCategories.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(deleteTrainingCategory.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestTrainingCategories.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.trainingCategories = action.payload;
			})
			.addCase(deleteTrainingCategory.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.trainingCategories = state.trainingCategories.filter(item => item.id !== action.payload);
			});
	},
});

export const getTrainingCategories = (state: RootState) => state.trainingCategories;

export const getTrainingCategoriesList = createSelector(getTrainingCategories,
	(categories => categories.trainingCategories));

export default trainingCategoriesSlice.reducer;
