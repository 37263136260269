import { FetchAPI } from 'utils/fetch/fetch-api';
import { UpdateVideoDTO } from 'utils/types/video';
import { RequestBody, RequestQuery } from 'utils/types/request-types';
import {
	CreateTrainingDTO,
	DeleteTrainingDTO,
	GetTrainingDTO,
	TrainingsListDTO,
	UpdateTrainingDTO,
} from 'utils/types/trainings';

class TrainingsService extends FetchAPI {
	getTrainings = (query?: RequestQuery): Promise<TrainingsListDTO> => this.get<TrainingsListDTO>(query);

	getTraining = (query?: RequestQuery): Promise<GetTrainingDTO> => this.get<GetTrainingDTO>(query);

	createTraining = (body: RequestBody): Promise<CreateTrainingDTO> =>
		this.post<CreateTrainingDTO>(body);

	updateTraining = (query?: RequestQuery, body?: RequestBody): Promise<UpdateTrainingDTO> =>
		this.put<UpdateTrainingDTO>(query, body);

	assignTrainingCategory = (query?: RequestQuery, body?: RequestBody): Promise<UpdateVideoDTO> =>
		this.put<UpdateVideoDTO>(query, body);

	deleteTraining = (query: RequestQuery): Promise<DeleteTrainingDTO> =>
		this.delete<DeleteTrainingDTO>(query);
}

export { TrainingsService };
