import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	CREATE_MEDIA,
	DELETE_MEDIA,
	GET_ALL_MEDIA,
	GET_MEDIA,
	UPDATE_MEDIA,
} from 'utils/constants/end-points';
import { MediaState } from 'utils/types/store';
import { MediaService } from 'services/media-service';
import { RequestBody, RequestQuery, UpdateMediaRequestBody } from 'utils/types/request-types';
import { RootState } from './index';

const initialState: MediaState = {
	media: [],
	status: FetchStatus.IDLE,
};

export const requestMediaList = createAsyncThunk(
	'get-media-list',
	async () => {
		const fetchInstance = new MediaService(GET_ALL_MEDIA);
		const result = await fetchInstance.getMediaList().then((l) => l);

		return result;
	},
);

export const requestMedia = createAsyncThunk(
	'get-media',
	async (query: RequestQuery) => {
		const fetchInstance = new MediaService(GET_MEDIA);
		const result = await fetchInstance.getMedia(query);

		return result;
	},
);

export const createMedia = createAsyncThunk(
	'create-media',
	async (body: RequestBody) => {
		const fetchInstance = new MediaService(CREATE_MEDIA);

		await fetchInstance.createMedia(body);
	},
);

export const updateMedia = createAsyncThunk(
	'update-media',
	async (reqBody: UpdateMediaRequestBody) => {
		const fetchInstance = new MediaService(UPDATE_MEDIA);
		const { query, body } = reqBody;

		await fetchInstance.updateMedia(query, body);
	},
);

export const deleteMedia = createAsyncThunk(
	'delete-media',
	async ({ id }: RequestQuery) => {
		const fetchInstance = new MediaService(DELETE_MEDIA);

		await fetchInstance.deleteMedia({ id });

		return Number(id);
	},
);

const mediaSlice = createSlice({
	name: 'medias',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createMedia.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestMediaList.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(deleteMedia.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestMediaList.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.media = action.payload;
			})
			.addCase(deleteMedia.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.media = state.media.filter(item => item.id !== action.payload);
			})
			.addCase(createMedia.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

const getMedias = (state: RootState) => state.media;

export const getMediaList = createSelector(getMedias, media => media.media);

export default mediaSlice.reducer;
