import classNames from 'classnames';
import { Field } from 'formik';
import { CheckboxProps } from 'formik-antd';
import { PropsWithChildren } from 'react';
import './Checkbox.scss';

type Props = {
	label?: string;
	name: string;
};

const Checkbox = (props: PropsWithChildren<Props & CheckboxProps>): JSX.Element => {
	const { children, className, label, ...restProps } = props;

	return (
		<>
			<label className="checkbox-wrap">
				<Field
					{...restProps}
					className={classNames('checkbox', className)}
					type="checkbox"
				>
					{children}
				</Field>

				<div className="checkbox-icon"></div>

				{label}
			</label>
		</>
	);
};

export { Checkbox };
