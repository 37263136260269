import { useEffect } from 'react';
import { BackButton, Button, ProfileDropdown, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RequestQuery } from 'utils/types/request-types';
import { AdminsList as AdminsListType, AdminsListDTO } from 'utils/types/admins';
import { getAdminsList, lockAdmin, requestAdminsList, unlockAdmin } from 'store/admins';
import { checkRoundGreen, lockOrange, editPurple } from 'assets/images';
import { Link } from 'react-router-dom';
import { columns } from './AdminsListColumns';
import './AdminsList.scss';

const AdminsList = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const adminsList = useAppSelector<AdminsListDTO>(getAdminsList);

	interface ActionsProps {
		id: RequestQuery;
		isLocked: boolean;
	}

	const Actions = ({ id, isLocked }: ActionsProps): JSX.Element => (
		<>
			{isLocked
				? (
					<Button
						className="btn-link"
						icon={<img alt="" src={checkRoundGreen} />}
						onClick={() => dispatch(unlockAdmin(id))}
						text="Unlock"
					/>)
				: (
					<Button
						className="btn-link"
						icon={<img alt="" src={lockOrange} />}
						onClick={() => dispatch(lockAdmin(id))}
						text="Lock"
					/>)}

			<Link to={`edit/${id.id}`}>
				<Button className="btn-link" icon={<img alt="" src={editPurple} />} text="Edit" />
			</Link>
		</>
	);

	const normalize = (coll: AdminsListDTO): AdminsListType => coll.map(({
		id,
		fullName,
		email,
		role,
		isLocked,
	}) => ({
		key: id,
		name: fullName,
		email,
		role,
		actions: <Actions id={{ id: `${id}` }} isLocked={isLocked} />,
	}));

	useEffect(() => {
		dispatch(requestAdminsList());
	}, [dispatch]);

	const normalizedAdmins: AdminsListType = normalize(adminsList);

	return (
		<div className="admins-list">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="admins-list__top">
					<div className="page-title">
						<BackButton />
						<h1>Admin List</h1>
					</div>
					<Link to="add-admin">
						<Button className="btn-purple" text="ADD ADMIN" />
					</Link>
				</div>
				<div className="admins-list__wrap">
					<Table
						columns={columns}
						data={normalizedAdmins}
					/>
				</div>
			</div>
		</div>
	);
};

export { AdminsList };
