import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import { LoadingSpinner } from 'components';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import './index.scss';

ReactDOM.render(
	<StrictMode>
		<Provider store={store}>
			<PersistGate loading={<LoadingSpinner />} persistor={persistor}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</StrictMode>,
	document.getElementById('root'),
);

serviceWorker.unregister();
