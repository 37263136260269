export class FetchClientErrorException extends Error {
	constructor(public statusCode: number, message: string) {
		super(`${statusCode}: ${message}`);
	}
}

export class FetchServerErrorException extends Error {
	constructor(public statusCode: number, message: string) {
		super(`${statusCode}: ${message}`);
	}
}

export class FetchRedirectionException extends Error {
	constructor(public statusCode: number, message: string) {
		super(`${statusCode}: ${message}`);
	}
}

export class FetchInvalidResponseException extends Error {
	constructor() {
		super('Response is not valid json');
	}
}

export class FetchUnknownErrorException extends Error {
	constructor() {
		super('Unknown error');
	}
}
