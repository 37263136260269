import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { Credentials } from 'utils/types/auth';
import { AuthorizationService } from 'services/authorization-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import { RootState } from './index';

type AuthState = {
	accessToken: string | null;
	expirationDate: string | null;
	status: FetchStatus;
};

const initialState: AuthState = {
	accessToken: null,
	expirationDate: null,
	status: FetchStatus.IDLE,
};

export const loginAsync = createAsyncThunk(
	'auth/authorization',
	async (credentials: Credentials) => {
		const fetchInstance = new AuthorizationService();
		const response = await fetchInstance.authorization(credentials);

		return response;
	},
);

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signOut: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(loginAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.accessToken = action.payload.accessToken;
				state.expirationDate = action.payload.expirationDate;
			});
	},
});

const getAuth = (state: RootState) => state.auth;

export const getAccessToken = createSelector(getAuth, auth => auth.accessToken);

export const getLoggedIn = createSelector((state: RootState) => state.auth, auth => !!auth.accessToken);

const { signOut } = authSlice.actions;

export { signOut };

export default authSlice.reducer;
