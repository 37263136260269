/* eslint-disable @typescript-eslint/no-unsafe-return */
import fetchIntercept from 'fetch-intercept';
import { store } from 'store';
import { getAccessToken } from 'store/auth';
import { makeAuthHeader } from 'utils/helpers';

const unregister = fetchIntercept.register({
	request: function(url, config) {
		const token = getAccessToken(store.getState());
		const authorizationHeader: Record<string, string> = makeAuthHeader(token as string);

		if (token) {
			const { headers } = config;
			const newHeaders = Object.assign(headers, authorizationHeader);

			const newConfig = {
				...config,
				headers: newHeaders,
			};

			return [url, newConfig];
		}

		return [url, config];
	},

	requestError: function(error) {
		// Called when an error occured during another 'request' interceptor call
		return Promise.reject(error);
	},

	response: function(response) {
		// Modify the response object
		return response;
	},

	responseError: function(error) {
		// Handle a fetch error
		return Promise.reject(error);
	},
});

export { unregister };
