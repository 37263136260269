import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_ALL_DOCUMENTS, GET_DOCUMENT, UPDATE_DOCUMENT,
} from 'utils/constants/end-points';
import { DocumentsState } from 'utils/types/store';
import { RequestQuery } from 'utils/types/request-types';
import { DocumentsService } from 'services/documents-service';
import { UpdateDocumentRequest } from 'utils/types/documents';
import { RootState } from './index';

const initialState: DocumentsState = {
	documents: [],
	status: FetchStatus.IDLE,
};

export const requestDocuments = createAsyncThunk(
	'documents/get-all-documents',
	async (query: RequestQuery) => {
		const fetchInstance = new DocumentsService(GET_ALL_DOCUMENTS);
		const result = await fetchInstance.getDocuments(query);

		return result;
	},
);

export const requestDocument = createAsyncThunk(
	'documents/get-document',
	async (query: RequestQuery) => {
		const fetchInstance = new DocumentsService(GET_DOCUMENT);
		const result = await fetchInstance.getDocument(query);

		return result;
	},
);

export const updateDocument = createAsyncThunk(
	'documents/update-document',
	async (req: UpdateDocumentRequest) => {
		const fetchInstance = new DocumentsService(UPDATE_DOCUMENT);
		const { query: { id }, body } = req;
		const result = await fetchInstance.updateDocument({ id }, body);

		return result;
	},
);

const documentsSlice = createSlice({
	name: 'documents',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestDocuments.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestDocument.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateDocument.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestDocuments.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.documents = action.payload;
			})
			.addCase(requestDocument.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			})
			.addCase(updateDocument.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

const getDocuments = (state: RootState) => state.documents;

export const selectDocuments = createSelector(
	getDocuments, documents => documents.documents,
);

export default documentsSlice.reducer;
