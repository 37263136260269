import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RequestBody } from 'utils/types/request-types';
import { FetchStatus } from 'utils/enums/fetch-status';
import { StripeService } from 'services/stripe-service';
import {
	MAKE_STRIPE_PAYOUT,
	GET_STATUS_OF_STRIPE_PAYOUT,
	CANCEL_STRIPE_PAYOUT,
	GET_LINK_FOR_STRIPE_PAYOUT,
} from 'utils/constants/end-points';
import { StripeState } from 'utils/types/store';
import { RootState } from './index';

const initialState: StripeState = {
	paymentLink: {
		paymentLink: null,
	},
	payoutStatus: {
		status: null,
	},
	status: FetchStatus.IDLE,
};

export const requestStripePayout = createAsyncThunk(
	'stripe/request-stripe-payout',
	async (paymentIdHash: RequestBody) => {
		const fetchInstance = new StripeService(MAKE_STRIPE_PAYOUT);
		const result = await fetchInstance.requestStripePayout(paymentIdHash);

		return result;
	},
);

export const requestStripePayoutLink = createAsyncThunk(
	'stripe/get-stripe-payout-link',
	async () => {
		const fetchInstance = new StripeService(GET_LINK_FOR_STRIPE_PAYOUT);
		const result = await fetchInstance.getStripePayoutLink();

		return result;
	},
);

export const requestStripePayoutStatus = createAsyncThunk(
	'stripe/get-stripe-payout-status',
	async () => {
		const fetchInstance = new StripeService(GET_STATUS_OF_STRIPE_PAYOUT);
		const result = await fetchInstance.getStripePayoutStatus();

		return result;
	},
);

export const cancelStripePayout = createAsyncThunk(
	'stripe/cancel-stripe-payout',
	async () => {
		const fetchInstance = new StripeService(CANCEL_STRIPE_PAYOUT);
		const result = await fetchInstance.cancelStripePayout();

		return result;
	},
);

const stripeSlice = createSlice({
	name: 'stripe',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestStripePayout.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestStripePayoutLink.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestStripePayoutStatus.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(cancelStripePayout.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestStripePayout.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.paymentLink = action.payload;
			})
			.addCase(requestStripePayoutLink.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.paymentLink = action.payload;
			})
			.addCase(requestStripePayoutStatus.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.payoutStatus = action.payload;
			})
			.addCase(cancelStripePayout.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

const getStripe = (state: RootState) => state.stripe;

export const selectStripePayoutLink = createSelector(
	getStripe, stripe => stripe.paymentLink,
);

export const selectStripePayoutStatus = createSelector(
	getStripe, stripe => stripe.payoutStatus,
);

export default stripeSlice.reducer;
