import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	APPROVE_COMPLAINT,
	DELETE_COMPLAINT,
	GET_ALL_COMPLAINTS,
} from 'utils/constants/end-points';
import { ComplaintsState } from 'utils/types/store';
import { RequestQuery } from 'utils/types/request-types';
import { ComplaintsService } from 'services/complaints-service';
import { RootState } from './index';

const initialState: ComplaintsState = {
	complaintsList: [],
	status: FetchStatus.IDLE,
};

export const requestComplaintsList = createAsyncThunk(
	'complaints/list',
	async () => {
		const fetchInstance = new ComplaintsService(GET_ALL_COMPLAINTS);
		const result = await fetchInstance.getComplaints();

		return result;
	},
);

export const approveComplaint = createAsyncThunk(
	'approve-admin',
	({ id }: RequestQuery) => {
		const fetchInstance = new ComplaintsService(APPROVE_COMPLAINT);

		fetchInstance.approveComplaint({ id });

		return Number(id);
	},
);

export const deleteComplaint = createAsyncThunk(
	'delete-complaints',
	({ id }: RequestQuery) => {
		const fetchInstance = new ComplaintsService(DELETE_COMPLAINT);

		fetchInstance.deleteComplaint({ id });

		return Number(id);
	},
);

const complaintsSlice = createSlice({
	name: 'complaints',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestComplaintsList.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestComplaintsList.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.complaintsList = action.payload;
			})
			.addCase(approveComplaint.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.complaintsList = state.complaintsList
					.filter(({ id }) => (id !== action.payload));
			})
			.addCase(deleteComplaint.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.complaintsList = state.complaintsList
					.filter(({ id }) => (id !== action.payload));
			});
	},
});

const getComplaints = (state: RootState) => state.complaints;

export const getComplaintsList = createSelector(getComplaints, complaints => complaints.complaintsList);

export default complaintsSlice.reducer;
