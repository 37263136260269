/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Layout, Menu, Row, Col, Image, MenuProps } from 'antd';
import { logo } from 'assets/images';
import { Bank, Country } from 'utils/types/glossaries';
import { useAddress, useBanks } from 'utils/hooks';
import './MainSider.scss';

const { Sider, Content } = Layout;

const MainSider = (): JSX.Element => {
	const getCurrentTabPath = () => '/' + window.location.pathname.split('/')[1];
	const [current, setCurrent] = useState<string>(getCurrentTabPath());
	const banks: Array<Bank> | null = useBanks();
	const countries: Array<Country> | null = useAddress();

	const onClick: MenuProps['onClick'] = () => {
		setCurrent(getCurrentTabPath());
	};

	return (
		<div className="main-sider">
			<Layout>
				<Sider className="main-sider__sider" width={260}>
					<Row justify="center">
						<Col>
							<Image
								alt="Company Logo"
								className="main-sider__logo"
								preview={false}
								src={logo}
								width={150}
							/>
						</Col>
					</Row>
					<br />
					<nav>
						<Menu
							defaultSelectedKeys={['/']}
							mode="inline"
							onClick={onClick}
							selectedKeys={[current]}
							theme="dark"
						>
							<Menu.Item key="/">
								<Link to="/">Dashboard</Link>
							</Menu.Item>
							<Menu.Item key="/admins">
								<Link to="/admins">Admins</Link>
							</Menu.Item>
							<Menu.Item key="/users">
								<Link to="/users">Users</Link>
							</Menu.Item>
							<Menu.Item key="/documents">
								<Link to="/documents">Documents</Link>
							</Menu.Item>
							<Menu.Item key="/financial">
								<Link to="/financial">Financial</Link>
							</Menu.Item>
							<Menu.Item key="/content">
								<Link to="/content">Content</Link>
							</Menu.Item>
							<Menu.Item key="/statistics">
								<Link to="/statistics">Statistics</Link>
							</Menu.Item>
							<Menu.Item key="/complaints">
								<Link to="/complaints">Complaints</Link>
							</Menu.Item>
							<Menu.Item key="/settings">
								<Link to="/settings">Settings</Link>
							</Menu.Item>
						</Menu>
					</nav>
				</Sider>
				<Content>
					<Outlet />
				</Content>
			</Layout>
		</div>
	);
};

export { MainSider };
