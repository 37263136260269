import { Routes, Route } from 'react-router-dom';
import { Statistics } from 'views/statistics';
import { NotFound } from 'views/not-found';

const StatisticsRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Statistics />} index />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { StatisticsRoutes };
