import { useState, useEffect } from 'react';
import { Input, Modal, Radio } from 'antd';
import { Button } from 'components';
import { useAppDispatch } from 'utils/hooks';
import { DocumentDTO, DocumentStatuses, UpdateDocumentRequest } from 'utils/types/documents';
import { requestDocument, updateDocument } from 'store/documents';
import { useProtectedDocument } from 'utils/hooks/use-protected-document';

const documentModalWindow = (document: DocumentDTO): JSX.Element | null => {
	if (!document) {
		return null;
	}

	const dispatch = useAppDispatch();
	const { TextArea } = Input;
	const { id, status } = document;
	const documentLoaded = status !== DocumentStatuses.NOT_LOADED && status !== null;

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [statusValue, setStatusValue] = useState<DocumentStatuses>(status);
	const [commentValue, setCommentValue] = useState<string | null>(null);

	useEffect(() => {
		if (id) dispatch(requestDocument({ id: String(id) }))
			.then((res) => {

				const { comment, status } = res.payload as DocumentDTO;

				setStatusValue(status);
				setCommentValue(comment);
			});
	}, [dispatch]);

	const handleDocumentUpdate = () => {
		const req: UpdateDocumentRequest = {
			query: { id: String(id) },
			body: {
				status: statusValue,
				comment: commentValue,
			},
		};

		dispatch(updateDocument(req)).then(() => setIsModalVisible(false));
	};

	return (
		<>
			{documentLoaded ? (
				<>
					<button className="btn btn-outline-orange" onClick={() => setIsModalVisible(true)}>
						{status}
					</button>
					<Modal
						footer={[
							<Button key="cancel" onClick={() => setIsModalVisible(false)} type="default">
								Cancel
							</Button>,
							<Button key="submit" onClick={handleDocumentUpdate} type="primary">
								Submit
							</Button>,
						]}
						onCancel={() => setIsModalVisible(false)}
						onOk={() => setIsModalVisible(false)}
						title="Document Status"
						visible={isModalVisible}
					>
						<p>
							Document:
							{' '}
							<a href={useProtectedDocument(id)} target="_blank" type="application/pdf">
								Click to View
							</a>
						</p>
						<Radio.Group
							buttonStyle="solid"
							defaultValue={statusValue}
							name="status"
							onChange={(e) => setStatusValue(e.target.value as DocumentStatuses)}
						>
							<Radio.Button value="Verified">Verified</Radio.Button>
							<Radio.Button value="Processing">Processing</Radio.Button>
							<Radio.Button value="Not verified">Not verified</Radio.Button>
						</Radio.Group>
						<TextArea
							defaultValue={commentValue ?? ''}
							name="comment"
							onChange={(e) => setCommentValue(e.target.value)}
							placeholder="Leave a comment..."
							rows={3}
						/>
					</Modal>
				</>
			) : (
				<p>
					Not Loaded
				</p>
			)
			}
		</>
	);
};

export const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
	},
	{
		title: 'Document',
		dataIndex: 'document',
		key: 'document',
		render: documentModalWindow,
	},
	{
		title: 'Phone',
		dataIndex: 'phone',
		key: 'phone',
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
];
