import { ProtectedImage } from 'components/protected-image';
import { ImageContainer } from 'utils/types/common';

const renderBooleanFlag = (flag: boolean) => (
	flag ? <p style={{ 'fontSize': 20 }}>✓</p> : <p style={{ 'fontSize': 25 }}>☓</p>
);

export const columns = [
	{
		title: 'User Avatar',
		dataIndex: 'userAvatarImageId',
		key: 'userAvatarImageId',
		render: (userAvatarImageId: number) =>
			(<ProtectedImage containerType={ImageContainer.USER_AVATAR} id={userAvatarImageId} />),
	},
	{
		title: 'User ID',
		dataIndex: 'userId',
		key: 'userId',
	},
	{
		title: 'Group Avatar',
		dataIndex: 'groupAvatarImageId',
		key: 'groupAvatarImageId',
		render: (coll: Array<number>) =>
			(
				<>
					{coll ? coll.map((id) => (
						<ProtectedImage containerType={ImageContainer.GROUP_AVATAR} id={id} key={id} />
					)) : null}
				</>
			),
	},
	{
		title: 'Group ID',
		dataIndex: 'groupId',
		key: 'groupId',
	},
	{
		title: 'User Images',
		dataIndex: 'userImages',
		key: 'userImages',
		render: (coll: Array<Record<string, number>>) =>
			(
				<>
					{coll ? coll.map(({ imageId }) => (
						<ProtectedImage containerType={ImageContainer.USER_IMAGES} id={imageId} key={imageId} />
					)) : null}
				</>
			),
	},
	{
		title: 'Group Images',
		dataIndex: 'groupImages',
		key: 'groupImages',
		render: (coll: Array<Record<string, number>>) =>
			(
				<>
					{coll ? coll.map(({ imageId }) => (
						<ProtectedImage containerType={ImageContainer.GROUP_IMAGES} id={imageId} key={imageId} />
					)) : null}
				</>
			),
	},
	{
		title: 'Is Indecent?',
		dataIndex: 'isIndecent',
		key: 'isIndecent',
		render: renderBooleanFlag,
	},
	{
		title: 'Is Rude?',
		dataIndex: 'isRude',
		key: 'isRude',
		render: renderBooleanFlag,
	},
	{
		title: 'Is Sensitive?',
		dataIndex: 'isSensitive',
		key: 'isSensitive',
		render: renderBooleanFlag,
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
	},
];
