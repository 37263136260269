import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'utils/enums/fetch-status';
import { RequestQuery } from 'utils/types/request-types';
import {
	GET_BANK_ACCOUNT,
} from 'utils/constants/end-points';
import { BankAccountState } from 'utils/types/store';
import { BankAccountService } from 'services/bank-account-service';

const initialState: BankAccountState = {
	status: FetchStatus.IDLE,
};

export const requestBankAccount = createAsyncThunk(
	'bank-account/get-bank-account',
	async (query: RequestQuery) => {
		const fetchInstance = new BankAccountService(GET_BANK_ACCOUNT);
		const result = await fetchInstance.getBankAccount(query);

		return result;
	},
);

const bankAccountSlice = createSlice({
	name: 'bank-account',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestBankAccount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestBankAccount.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

export default bankAccountSlice.reducer;
