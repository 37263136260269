import { Upload } from 'antd';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect } from 'react';
import { Form, Select } from 'formik-antd';
import { BackButton, Button, ProfileDropdown } from 'components';
import { InputText } from 'components/inputs-formik';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import {
	createTraining,
} from 'store/trainings';
import { uploadVideo, uploadImage } from 'store/upload';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { UserLevels } from 'utils/enums/user-level';
import { userLevels } from 'utils/constants/user-levels';
import { assignPreviewImage } from 'store/video';
import { addTrainingValidationSchema } from 'utils/validation/schemes';
import { getTrainingCategoriesList, requestTrainingCategories } from 'store/training-categories';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addMediaIco, uploadCover, arrowDownPurple } from 'assets/images';
import './AddTraining.scss';

const AddTraining = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const trainingCategories = useAppSelector(getTrainingCategoriesList);

	type FormValues = {
		video?: File;
		cover?: File;
		title?: string;
		description?: string;
		categoryId?: number;
		level: UserLevels;
	};

	const initialValues: FormValues = {
		level: UserLevels.GOAL_GETTER_I,
	};

	useEffect(() => {
		dispatch(requestTrainingCategories());
	}, [dispatch]);

	const handleSubmit = async ({ video, cover, title, categoryId, description, level }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {
		if (video && cover && title && categoryId && description && !isNaN(level)) {
			const id = toast.loading('Please wait...');

			try {
				const { videoId } = await dispatch(uploadVideo({ video, title })).unwrap();
				const { imageId } = await dispatch(uploadImage({ cover })).unwrap();

				await dispatch(assignPreviewImage({ videoId, imageId })).unwrap();

				await dispatch(createTraining({
					videoId,
					categoryId,
					level,
					title,
					description,
				})).unwrap();

				toast.update(id, { render: 'Success!', type: 'success', isLoading: false, autoClose: 5000 });
				navigate('/content/trainings/manage-trainings');
			} catch {
				toast.update(id, { render: 'Failed!', type: 'error', isLoading: false, autoClose: 5000 });
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ setFieldValue, dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form className="add-training__form">
			<div className="add-training__form-btns">
				<Upload
					accept={'video/*'}
					beforeUpload={() => false}
					maxCount={1}
					onChange={({ file }) => setFieldValue('video', file)}
				>
					<Button className="btn-purple" icon={<img alt="" src={addMediaIco} />} text="Upload a video" />
				</Upload>
				<Upload
					accept={'image/png'}
					beforeUpload={() => false}
					maxCount={1}
					onChange={({ file }) => setFieldValue('cover', file)}
				>
					<Button
						className="btn-purple"
						icon={<img alt="" src={uploadCover} />}
						text="Upload a training cover"
					/>
				</Upload>
			</div>
			<InputText name="title" placeholder="Enter training title" />
			<InputText name="description" placeholder="Enter training description" />
			<div className="add-training__form-footer">
				<div>
					<Select
						className="category"
						name="categoryId"
						placeholder="Category"
						suffixIcon={<img alt="" src={arrowDownPurple} />}
					>
						{trainingCategories.map(({ id, title }) => (
							<Select.Option key={id} value={id}>
								{title}
							</Select.Option>
						))}
					</Select>
					<Select
						className="level"
						name="level"
						placeholder="Level"
						suffixIcon={<img alt="" src={arrowDownPurple} />}
					>
						{Object.entries(userLevels).map(([key, value]) => (
							<Select.Option key={value} value={value}>
								{key}
							</Select.Option>
						))}
					</Select>
				</div>
				<Button
					className="btn-purple"
					disabled={!dirty || !isValid || isSubmitting}
					htmlType={HTMLButtonTypes.SUBMIT}
					text="Submit"
				/>
			</div>
		</Form>
	);

	return (
		<div className="add-training">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Add a new training</h1>
				</div>
				<div className="add-training__wrap">
					<Formik
						component={InnerForm}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={addTrainingValidationSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { AddTraining };
