import { useEffect } from 'react';
import { BackButton, Button, Table, ProfileDropdown } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RequestQuery } from 'utils/types/request-types';
import { GroupsList as GroupsListType, GroupsListDTO } from 'utils/types/groups';
import { getGroupsList, requestGroupsList } from 'store/groups';
import { columns } from './GroupsListColumns';
import './GroupsList.scss';

const GroupsList = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const groupList = useAppSelector<GroupsListDTO>(getGroupsList);
	const Actions = (id: RequestQuery): JSX.Element => (
		<>
			<Button className="btn-orange" onClick={() => console.log('Go To is clicked', id)} text="Go To" />
		</>
	);

	const normalize = (coll: GroupsListDTO): GroupsListType => coll.map(({
		id,
		name,
		creatorName,
		creatorNickname,
		membersAmount,
		avatarImageId,
	}) => ({
		key: id,
		name,
		creatorName,
		creatorNickname,
		membersAmount,
		avatarImageId,
		actions: <Actions id={`${id}`} />,
	}));

	useEffect(() => {
		dispatch(requestGroupsList());
	}, [dispatch]);

	const normalizedGroups: GroupsListType = normalize(groupList);

	return (
		<div className="groups-list">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Groups List</h1>
				</div>

				<div className="groups-list__table">
					<Table
						columns={columns}
						data={normalizedGroups}
					/>
				</div>
			</div>
		</div>
	);
};

export { GroupsList };
