import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { ProfileDropdown, Table } from 'components';
import { useAppDispatch } from 'utils/hooks';
import { InvoiceListItems, InvoiceListItemsDTO } from 'utils/types/invoices';
import { requestUsersInvoiceHistory } from 'store/invoices';
import { columns } from './CommissionsHistoryColumns';
import './CommissionsHistory.scss';

const CommissionsHistory = (): JSX.Element => {
	const { userId } = useParams<{ userId: string }>();
	const dispatch = useAppDispatch();
	const [commissionsHistory, setCommissionsHistory] = useState<InvoiceListItemsDTO>([]);

	const normalize = (coll: InvoiceListItemsDTO): InvoiceListItems => coll.map(({
		id,
		updatedAt,
		actualRequestBalance,
		currentBalance,
		status,
		comment,
	}) => ({
		invoiceId: id,
		date: dayjs(updatedAt).format('DD/MM/YYYY HH:mm:ss'),
		actualRequestBalance,
		currentBalance,
		comment,
		withdrawalInfo: {
			invoiceId: id,
			status,
			comment,
			balance: currentBalance,
		},
	}));

	useEffect(() => {
		if (userId) {
			dispatch(requestUsersInvoiceHistory({ userId })).then((res) => {
				setCommissionsHistory(res.payload as InvoiceListItemsDTO);
			});
		}
	}, [dispatch, userId]);

	const normalizedCommissionsHistory: InvoiceListItems = normalize(commissionsHistory);

	return (
		<div className="commissions">
			<div className="header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="container-item">
					<div className="commissions__top">
						<div className="container-item__header">
							<h2 className="container-item__header-text">User's Commission History</h2>
						</div>
					</div>

					<div className="container-item__body">
						<Table
							columns={columns}
							data={normalizedCommissionsHistory}
							rowKey="invoiceId"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export { CommissionsHistory };
