/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { configureStore, ThunkAction, Action, combineReducers, AnyAction, isRejected } from '@reduxjs/toolkit';
import storage from 'redux-persist/es/storage';
import authReducer, { signOut } from 'store/auth';
import adminsReducer from 'store/admins';
import complaintsReducer from 'store/complaints';
import usersReducer from 'store/users';
import groupsReducer from 'store/groups';
import leadsReducer from 'store/leads';
import dashboardReducer from 'store/dashboard';
import glossariesReducer from 'store/glossaries';
import adminProfileReducer from 'store/admin-profile';
import trainingsReducer from 'store/trainings';
import trainingCategoriesReducer from 'store/training-categories';
import mediaReducer from 'store/media';
import financeReducer from 'store/finance';
import settingsReducer from 'store/settings';
import statisticsReducer from 'store/statistics';
import transactionsReducer from 'store/transactions';
import stripeReducer from 'store/stripe';
import invoicesReducer from 'store/invoices';
import documentsReducer from 'store/documents';
import salesReducer from 'store/sales';
import pixReducer from 'store/pix';
import { isDevelopment } from 'utils/constants/base';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import { toast } from 'react-toastify';

const reducers = combineReducers({
	auth: authReducer,
	admins: adminsReducer,
	complaints: complaintsReducer,
	users: usersReducer,
	groups: groupsReducer,
	leads: leadsReducer,
	dashboard: dashboardReducer,
	glossaries: glossariesReducer,
	adminProfile: adminProfileReducer,
	trainings: trainingsReducer,
	trainingCategories: trainingCategoriesReducer,
	media: mediaReducer,
	finance: financeReducer,
	settings: settingsReducer,
	statistics: statisticsReducer,
	transactions: transactionsReducer,
	stripe: stripeReducer,
	invoices: invoicesReducer,
	documents: documentsReducer,
	sales: salesReducer,
	pix: pixReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appReducer = (state: any, action: AnyAction) => {
	let isTokenExpired = false;

	if (isRejected(action)) {
		toast.error(`${action.error.message as string} ${action.type}`);

		if (parseInt(action.error.message as string, 10) === 401) {
			isTokenExpired = true;
		}
	}

	if (action.type === signOut().type || isTokenExpired) {
		storage.removeItem('persist:fluency-strikers-admin-auth');
		return reducers(undefined, action);
	}

	return reducers(state, action);
};

const persistConfig = {
	key: 'fluency-strikers-admin-auth',
	storage,
	whitelist: ['auth'],
	debug: isDevelopment,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
