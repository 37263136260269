import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_PIX,
} from 'utils/constants/end-points';
import { PixState } from 'utils/types/store';
import { RequestQuery } from 'utils/types/request-types';
import { PixService } from 'services/pix-service';

const initialState: PixState = {
	status: FetchStatus.IDLE,
};

export const requestPix = createAsyncThunk(
	'pix/get-pix',
	async (query: RequestQuery) => {
		const fetchInstance = new PixService(GET_PIX);
		const result = await fetchInstance.getPix(query);

		return result;
	},
);

const pixSlice = createSlice({
	name: 'pix',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestPix.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestPix.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

export default pixSlice.reducer;
