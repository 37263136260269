import { RequestMethods } from 'utils/enums/request-methods';
import { RequestBody, RequestQuery } from 'utils/types/request-types';
import {
	REDIRECTION_ERROR_STATUS_CODES,
	SERVER_ERROR_STATUS_CODES,
	CLIENT_ERROR_STATUS_CODES,
} from './constants';
import {
	FetchInvalidResponseException,
	FetchUnknownErrorException,
	FetchClientErrorException,
	FetchRedirectionException,
	FetchServerErrorException,
} from './exceptions';

export class Fetch {
	constructor(
		private readonly url: string,
		private headers: Record<string, string>,
	) {}

	async post<T>(body: RequestBody): Promise<T> {
		const response = await fetch(this.url, {
			method: RequestMethods.POST,
			body: JSON.stringify(body),
			headers: this.headers,
		});

		return this.validateResponse<T>(response);
	}

	async get<T>(query?: RequestQuery): Promise<T> {
		const url = this.createQueryString(query);
		const response = await fetch(url, {
			method: 'GET',
			headers: this.headers,
		});

		return this.validateResponse<T>(response);
	}

	async put<T>(query?: RequestQuery, body?: RequestBody): Promise<T> {
		const url = this.createQueryString(query);
		const response = await fetch(url, {
			method: RequestMethods.PUT,
			body: JSON.stringify(body),
			headers: this.headers,
		});

		return this.validateResponse<T>(response);
	}

	async patch<T>(query?: RequestQuery, body?: RequestBody): Promise<T> {
		const url = this.createQueryString(query);
		const response = await fetch(url, {
			method: RequestMethods.PATCH,
			body: JSON.stringify(body),
			headers: this.headers,
		});

		return this.validateResponse<T>(response);
	}

	async delete<T>(query?: RequestQuery): Promise<T> {
		const url = this.createQueryString(query);
		const response = await fetch(url, {
			method: RequestMethods.DELETE,
			headers: this.headers,
		});

		return this.validateResponse<T>(response);
	}

	async upload<T>(body: FormData, query?: RequestQuery): Promise<T> {
		const url = this.createQueryString(query);

		const response = await fetch(url, {
			method: RequestMethods.POST,
			body,
			headers: this.headers,
		});

		return this.validateResponse<T>(response);
	}

	async download<T>(query?: RequestQuery): Promise<T> {
		const url = this.createQueryString(query);

		const response = await fetch(url, {
			method: RequestMethods.GET,
			headers: this.headers,
		});

		return this.validateResponse<T>(response);
	}

	private createQueryString(query?: RequestQuery): string {
		const url = new URL(this.url);

		if (query) {
			Object.keys(query).forEach(param =>
				url.searchParams.append(param, query[param]));
		}
		return url.toString();
	}

	appendHeaders(headers: Record<string, string>): void {
		Object.assign(this.headers, headers);
	}

	private async validateResponse<R>(response: Response): Promise<R> {
		if (response.ok) {
			const responseJson = await response.json() as R;

			if (typeof responseJson === 'object') {
				return responseJson;
			}
			throw new FetchInvalidResponseException();
		}
		const { status, statusText } = response;

		if (status >= SERVER_ERROR_STATUS_CODES) {
			throw new FetchServerErrorException(status, statusText);
		} else if (status >= CLIENT_ERROR_STATUS_CODES) {
			throw new FetchClientErrorException(status, statusText);
		} else if (status > REDIRECTION_ERROR_STATUS_CODES) {
			throw new FetchRedirectionException(status, statusText);
		}
		throw new FetchUnknownErrorException();
	}
}
