import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	// GET_PERCENT,
	// UPDATE_PERCENT,
	GET_REGISTRATION_FORM_STATUS,
	UPDATE_REGISTRATION_FORM_STATUS,
} from 'utils/constants/end-points';
import { SettingsState } from 'utils/types/store';
import { SettingsService } from 'services/settings-service';
import { RequestBody } from 'utils/types/request-types';
// import { PercentDTO } from 'utils/types/settings';
import { RootState } from './index';

const initialState: SettingsState = {
	// percent: {
	// 	percent: 0,
	// },
	registrationStatus: true,
	status: FetchStatus.IDLE,
};

// export const requestPercent = createAsyncThunk(
// 	'get-percent',
// 	async () => {
// 		const fetchInstance = new SettingsService(GET_PERCENT);
// 		const result = await fetchInstance.getPercent();

// 		return result;
// 	},
// );

// export const updatePercent = createAsyncThunk(
// 	'update-percent',
// 	async (body: PercentDTO) => {
// 		const fetchInstance = new SettingsService(UPDATE_PERCENT);
// 		const result = await fetchInstance.updatePercent(body);

// 		return result;
// 	},
// );

export const requestRegistrationFormStatus = createAsyncThunk(
	'get-registration-form-status',
	async () => {
		const fetchInstance = new SettingsService(GET_REGISTRATION_FORM_STATUS);
		const result = await fetchInstance.getRegistrationFormStatus();

		return result;
	},
);

export const updateRegistrationFormStatus = createAsyncThunk(
	'update-registration-form-status',
	async (body: RequestBody) => {
		const fetchInstance = new SettingsService(UPDATE_REGISTRATION_FORM_STATUS);
		const result = await fetchInstance.updateRegistrationFormStatus(undefined, body);

		return result;
	},
);

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// .addCase(requestPercent.fulfilled, (state, action) => {
			// 	state.status = FetchStatus.IDLE;
			// 	state.percent = action.payload;
			// })
			// .addCase(updatePercent.fulfilled, (state, action) => {
			// 	state.status = FetchStatus.IDLE;
			// 	state.percent = action.payload;
			// })
			.addCase(requestRegistrationFormStatus.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateRegistrationFormStatus.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestRegistrationFormStatus.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.registrationStatus = action.payload.registrationStatus;
			})
			.addCase(updateRegistrationFormStatus.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.registrationStatus = action.payload.registrationStatus;
			});
	},
});

const getSettings = (state: RootState) => state.settings;

// export const getPercent = createSelector(getSettings, settings => settings.percent.percent);
export const getRegistrationFormStatus = createSelector(getSettings, settings =>
	(settings.registrationStatus));

export default settingsSlice.reducer;
