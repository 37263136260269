import { PixKey } from 'utils/types/pix';

const getPixKeyText = (value: PixKey): string => {
	switch (value) {
		case PixKey.BANK_ACCOUNT: return 'Bank account';
		case PixKey.CNPJ: return 'CNPJ';
		case PixKey.CPF: return 'CPF';
		case PixKey.EMAIL: return 'Email';
		case PixKey.PHONE: return 'Phone number';

		default: return value;
	}
};

export default getPixKeyText;
