import { BackButton, Button, ProfileDropdown } from 'components';
import { InputText } from 'components/inputs-formik';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form, Select } from 'formik-antd';
import { useAppDispatch } from 'utils/hooks';
import {
	createAdmin,
} from 'store/admins';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { addAdminValidationSchema } from 'utils/validation/schemes';
import { AdminRoles } from 'utils/enums/admin-roles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { arrowDownPurple } from 'assets/images';
import './AddAdmin.scss';

const AddAdmin = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type FormValues = {
		fullName?: string;
		email?: string;
		password?: string;
		role: AdminRoles;
	};

	const initialValues: FormValues = {
		role: AdminRoles.GENERAL,
	};

	const handleSubmit = async ({ fullName, email, password, role }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {
		if (fullName && email && password && role) {
			try {
				await dispatch(createAdmin({ fullName, email, password, role })).unwrap();
				toast.success('Success!');
				navigate('/admins');
			} catch {
				toast.error('Failed!');
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form className="add-admin__form">
			<InputText name="fullName" placeholder="Enter name" />
			<InputText name="email" placeholder="Enter email" />
			<InputText name="password" placeholder="Enter password" />
			<div className="add-admin__form-footer">
				<Select
					className="role"
					name="role"
					placeholder="Role"
					suffixIcon={<img alt="" src={arrowDownPurple} />}
				>
					{Object.values(AdminRoles).map((item) => (
						<Select.Option key={item} value={item}>
							{item}
						</Select.Option>
					))}
				</Select>
				<Button
					className="btn-purple"
					disabled={!dirty || !isValid || isSubmitting}
					htmlType={HTMLButtonTypes.SUBMIT}
					text="Submit"
				/>
			</div>
		</Form>
	);

	return (
		<div className="add-admin">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Add a new admin</h1>
				</div>
				<div className="add-admin__wrap">
					<Formik
						component={InnerForm}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={addAdminValidationSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { AddAdmin };
