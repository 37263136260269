import { FetchAPI } from 'utils/fetch/fetch-api';
import {
	NewRegistrationRequestsAmountDTO,
	NewSalesAmountDTO,
	NewUsersAmountDTO,
} from 'utils/types/statistics';

class StatisticsService extends FetchAPI {
	newSalesAmount = (): Promise<NewSalesAmountDTO> => this.get<NewSalesAmountDTO>();

	newUsersAmount = (): Promise<NewUsersAmountDTO> => this.get<NewUsersAmountDTO>();

	newRegistrationRequestsAmount = (): Promise<NewRegistrationRequestsAmountDTO> =>
		(this.get<NewRegistrationRequestsAmountDTO>());
}

export { StatisticsService };
