import { Modal } from 'antd';
import { getUserLocation } from 'utils/helpers';
import { useAddress } from 'utils/hooks';
import { Country } from 'utils/types/glossaries';
import { UserDTO } from 'utils/types/users';

type Props = {
	data: UserDTO;
	onClose: () => void;
};

const ContactDetailsModal = ({ data, onClose }: Props): JSX.Element | null => {
	const countries: Country[] | null = useAddress();

	if (!countries) return null;

	const getUserAddress = (): string => {
		const { countryId, stateId, city, zip, district, street, house, apartment } = data;
		const location = getUserLocation({ countryId, stateId, city, countries });

		return [zip, location, district, street, house, apartment].filter((v) => v).join(', ');
	};

	return (
		<Modal
			centered
			footer={null}
			onCancel={onClose}
			title="Contact Details"
			visible
		>
			<div>
				<p>
					Email:
					{' '}
					{data.email}
				</p>
				<p>
					Phone:
					{' '}
					{data.phoneNumber}
				</p>
				<p>
					Address:
					{' '}
					{getUserAddress()}
				</p>
			</div>
		</Modal>
	);
};

export default ContactDetailsModal;
