import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { GroupsService } from 'services/groups-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_GROUPS,
} from 'utils/constants/end-points';
import { GroupsState } from 'utils/types/store';
import { RootState } from './index';

const initialState: GroupsState = {
	groupsList: [],
	status: FetchStatus.IDLE,
};

export const requestGroupsList = createAsyncThunk(
	'groups/list',
	async () => {
		const fetchInstance = new GroupsService(GET_GROUPS);
		const result = await fetchInstance.groupsList();

		return result;
	},
);

const groupsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestGroupsList.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestGroupsList.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.groupsList = action.payload;
			});
	},
});

const getGroups = (state: RootState) => state.groups;

export const getGroupsList = createSelector(getGroups, groups => groups.groupsList);

export default groupsSlice.reducer;
