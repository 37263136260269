import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import './Button.scss';

type Props = {
	text?: string;
};

const Button = (props: PropsWithChildren<Props & ButtonProps>): JSX.Element => {
	const { children, className, text, type, ...restProps } = props;

	return (
		<AntdButton
			{...restProps}
			className={classNames('btn', className)}
			type={type ? type : 'primary'}
		>
			{text}
			{children}
		</AntdButton>
	);
};

export { Button };
