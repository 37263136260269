import { FetchAPI } from 'utils/fetch/fetch-api';
import {
	AdminDTO,
	AdminLockResponse,
	AdminsListDTO, AdminUnlockResponse, CreateAdminDTO, UpdateAdminDTO,
} from 'utils/types/admins';
import { RequestBody, RequestQuery } from 'utils/types/request-types';

class AdminsService extends FetchAPI {
	getAdmins = (): Promise<AdminsListDTO> => this.get<AdminsListDTO>();

	getAdmin = (query: RequestQuery): Promise<AdminDTO> => this.get<AdminDTO>(query);

	createAdmin = (body: RequestBody): Promise<CreateAdminDTO> => this.post<CreateAdminDTO>(body);

	updateAdmin = (query?: RequestQuery, body?: RequestBody): Promise<UpdateAdminDTO> =>
		this.put<UpdateAdminDTO>(query, body);

	lock = (query?: RequestQuery, body?: RequestBody): Promise<AdminLockResponse> => (
		this.patch<AdminLockResponse>(query, body)
	);

	unlock = (query?: RequestQuery, body?: RequestBody): Promise<AdminUnlockResponse> => (
		this.patch<AdminUnlockResponse>(query, body)
	);
}

export { AdminsService };
