import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { TransactionsService } from 'services/transactions-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_TOTAL_PENDING_PAYOUT_AMOUNT,
	GET_TRANSACTIONS_HISTORY,
} from 'utils/constants/end-points';
import { TransactionsState } from 'utils/types/store';
import { RequestQuery } from 'utils/types/request-types';
import { RootState } from './index';

const initialState: TransactionsState = {
	totalPendingPayoutAmount: {
		amount: 0,
		currency: 'BRL',
		usersAmount: 0,
		paymentIdHash: null,
		fee: 0,
		total: 0,
	},
	transactionsHistory: [],
	status: FetchStatus.IDLE,
};

export const requestTotalPendingPayoutAmount = createAsyncThunk(
	'transactions/get-total-pending-payout-amount',
	async () => {
		const fetchInstance = new TransactionsService(GET_TOTAL_PENDING_PAYOUT_AMOUNT);
		const result = await fetchInstance.getTotalPendingPayoutAmount();

		return result;
	},
);

export const requestTransactionsHistory = createAsyncThunk(
	'transactions/get-transactions-history',
	async (query: RequestQuery) => {
		const fetchInstance = new TransactionsService(GET_TRANSACTIONS_HISTORY);
		const result = await fetchInstance.getTransactionsHistory(query);

		return result;
	},
);

const transactionsSlice = createSlice({
	name: 'transactions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestTotalPendingPayoutAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestTransactionsHistory.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestTotalPendingPayoutAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.totalPendingPayoutAmount = action.payload;
			})
			.addCase(requestTransactionsHistory.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.transactionsHistory = action.payload;
			});
	},
});

const getTransactions = (state: RootState) => state.transactions;

export const selectTotalPendingPayoutAmount = createSelector(
	getTransactions, transactions => transactions.totalPendingPayoutAmount,
);

export const selectTransactionsHistory = createSelector(
	getTransactions, transactions => transactions.transactionsHistory,
);

export default transactionsSlice.reducer;
