import { Routes, Route } from 'react-router-dom';
import { Media } from 'views/content/media';
import { AddMedia } from 'views/content/media/add-media';
import { ManageMedia } from 'views/content/media/manage-media';
import { EditMedia } from 'views/content/media/manage-media/edit-media';
import { NotFound } from 'views/not-found';

const MediaRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Media />} index />
		<Route element={<AddMedia />} path="add-media" />
		<Route element={<ManageMedia />} path="manage-media" />
		<Route element={<EditMedia />} path="manage-media/edit/:id" />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { MediaRoutes };
