import { Routes, Route } from 'react-router-dom';
import { Settings } from 'views/settings';
import { NotFound } from 'views/not-found';

const SettingsRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Settings />} index />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { SettingsRoutes };
