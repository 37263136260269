import { Routes, Route } from 'react-router-dom';
import { Users } from 'views/users';
import { ManageRequests } from 'views/users/manage-requests';
import { UsersList } from 'views/users/users-list';
import { GroupsList } from 'views/users/groups-list';
import { LeadsBase } from 'views/users/leads-base';
import { NotFound } from 'views/not-found';

const UsersRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Users />} index />
		<Route element={<ManageRequests />} path="manage-requests" />
		<Route element={<UsersList />} path="users-list" />
		<Route element={<GroupsList />} path="groups-list" />
		<Route element={<LeadsBase />} path="leads-base" />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { UsersRoutes };
