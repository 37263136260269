import { useEffect } from 'react';
import { BackButton, Button, ProfileDropdown, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RequestQuery } from 'utils/types/request-types';
import { deleteMedia, getMediaList, requestMediaList } from 'store/media';
import { MediaList, MediaListDTO } from 'utils/types/media';
import { Link } from 'react-router-dom';
import { editPurple, deleteOrange } from 'assets/images';
import { columns } from './ManageMediaColumns';
import './ManageMedia.scss';

const ManageMedia = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const mediasList = useAppSelector<MediaListDTO>(getMediaList);
	const Actions = ({ id }: RequestQuery): JSX.Element => (
		<>
			<Link to={`edit/${id}`}>
				<Button className="btn-link" icon={<img alt="" src={editPurple} />} text="Edit" />
			</Link>
			<Button
				className="btn-link"
				icon={<img alt="" src={deleteOrange} />}
				onClick={() => dispatch(deleteMedia({ id }))}
				text="Delete"
			/>
		</>
	);

	const normalize = (coll: MediaListDTO): MediaList => coll.map(({
		id,
		previewImageId,
		title,
		link,
	}) => ({
		key: id,
		previewImageId,
		title,
		link,
		actions: <Actions id={`${id}`} />,
	}));

	useEffect(() => {
		dispatch(requestMediaList());
	}, [dispatch]);

	const normalizedMedia: MediaList = normalize(mediasList);

	return (
		<div className="manage-media">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Manage media</h1>
				</div>
				<div className="manage-media__wrap">
					<Table
						columns={columns}
						data={normalizedMedia}
						scroll={{ y: '60vh' }}
					/>
				</div>
			</div>
		</div>
	);
};

export { ManageMedia };
