import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { backOrange } from 'assets/images';
import './BackButton.scss';

const BackButton = () => {
	const navigate = useNavigate();
	const handleClick = () => navigate(-1);

	return (
		<div className="page-title__back-link">
			<Button className="page-title__back-btn" onClick={handleClick} type="link">
				<img alt="" src={backOrange} />
				Back
			</Button>
		</div>
	);
};

export { BackButton };
