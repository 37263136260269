import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { Sorting } from 'utils/types/common';

type Keys = 'defaultSortOrder' | 'onHeaderCell' | 'sortDirections' | 'sortOrder' | 'sorter' | 'showSorterTooltip';

export type SorterConfig<T> = Pick<ColumnType<T>, Keys>;

export const getDefaultSortParams = <T>(): SorterConfig<T> => ({
	sortDirections: ['ascend', 'descend', 'ascend'],
	sorter: true,
	showSorterTooltip: false,
});

export const getSortOrder = (sorting: Sorting): SortOrder => sorting === Sorting.ASC ? 'ascend' : 'descend';
