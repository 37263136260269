export enum GlossaryEnum {
	COUNTRIES = 'countries',
	LEGAL_NATURE_TYPES = 'legal-nature-types',
	BANKS = 'banks',
}

export interface GlossaryData {
	id: number;
	name: string;
}

export interface State extends GlossaryData {
	code: string;
}

export interface Country extends State {
	states: Array<State>;
	code: string;
}

export interface LegalNatureType {
	id: number;
	type: string;
}

export interface Bank {
	id: number;
	name: string;
}

export type Glossary = [];
