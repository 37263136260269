import { BaseEntity, SearchFilter, SortingFilter } from './common';

export type InvoicesDTO = Array<InvoiceDTO>;

export type InvoiceDTO = BaseEntity & {
	name: string;
	email: string;
	phone: string;
	userId: number;
	status: string;
	comment: string | null;
	actualRequestBalance: number;
	currentBalance: number;
	pixId: number | null;
	bankAccountId: number | null;
};

export enum InvoicesOrderBy {
	ALPHABET = 'alphabet',
	CREATED = 'created',
}

export interface InvoicesFilter extends SearchFilter, SortingFilter<InvoicesOrderBy> {
	analysisStatus: boolean;
	acceptedStatus: boolean;
	rejectedStatus: boolean;
}

export type InvoiceListItemsDTO = Array<InvoiceListItemDTO>;

export type InvoiceListItemDTO = BaseEntity & {
	actualRequestBalance: number;
	currentBalance: number;
	status: string;
	comment: string | null;
};

export type InvoiceListItems = Array<InvoiceListItem>;

export type InvoiceListItem = {
	date: string;
	invoiceId: number;
	actualRequestBalance: number;
	currentBalance: number;
	comment: string | null;
	withdrawalInfo: WithdrawalInfo;
};

export type WithdrawalInfo = {
	invoiceId: number;
	balance: number;
	status: string;
	comment: string | null;
};

export type UpdateInvoiceDTO = {
	status: string;
	comment: string | null;
};

export type UpdateInvoiceRequest = {
	query: {
		id: string;
	};
	body: UpdateInvoiceDTO;
};
