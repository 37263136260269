import { Routes, Route } from 'react-router-dom';
import { Content } from 'views/content';
import { MediaRoutes } from 'views/content/media';
import { TrainingRoutes } from 'views/content/trainings';
import { NotFound } from 'views/not-found';

const ContentRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Content />} index />
		<Route element={<MediaRoutes />} path="media/*" />
		<Route element={<TrainingRoutes />} path="trainings/*" />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { ContentRoutes };
