export const columns = [
	{
		title: 'Group Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Creator\'s Name',
		dataIndex: 'creatorName',
		key: 'creatorName',
	},
	{
		title: 'Creator\'s Nickname',
		dataIndex: 'creatorNickname',
		key: 'creatorNickname',
	},
	{
		title: 'Members',
		dataIndex: 'membersAmount',
		key: 'membersAmount',
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
	},
];
