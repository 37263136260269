import { ProfileDropdown } from 'components';
import { useEffect } from 'react';
import {
	getNewCommissionAmount,
	getNewGroupsAmount,
	getNewPendingUsersAmount,
	getNewSalesAmount,
	getNewUsersAmount,
	getUsersRegisteredTotal,
	requestNewCommissionAmount,
	requestNewGroupsAmount,
	requestNewRegistrationRequestsAmount,
	requestNewSalesAmount,
	requestNewUsersAmount,
	requestUsersRegisteredTotal,
} from 'store/dashboard';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { MetricDTO } from 'utils/types/dashboard';
import './Dashboard.scss';

const Dashboard = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const salesTodayAmount = useAppSelector<MetricDTO>(getNewSalesAmount);
	const usersTodayAmount = useAppSelector<MetricDTO>(getNewUsersAmount);
	const pendingUsersTodayAmount = useAppSelector<MetricDTO>(getNewPendingUsersAmount);
	const groupsTodayAmount = useAppSelector<MetricDTO>(getNewGroupsAmount);
	const commissionTodayAmount = useAppSelector<MetricDTO>(getNewCommissionAmount);
	const usersRegisteredTotal = useAppSelector<MetricDTO>(getUsersRegisteredTotal);

	useEffect(() => {
		dispatch(requestNewSalesAmount());
		dispatch(requestNewUsersAmount());
		dispatch(requestNewRegistrationRequestsAmount());
		dispatch(requestNewGroupsAmount());
		dispatch(requestNewCommissionAmount());
		dispatch(requestUsersRegisteredTotal());
	}, [dispatch]);

	return (
		<div className="dashboard">
			<div className="header">
				<ProfileDropdown />
			</div>

			<div className="dashboard__wrap">
				<div className="dashboard__dates">
					<div className="dashboard__date">Today</div>
				</div>

				<div className="dashboard__items">
					<div className="dashboard__item">
						<div className="dashboard__item-title">
							{salesTodayAmount.total}
						</div>
						<div className="dashboard__item-text">
							Sales Succeded
						</div>
					</div>
					<div className="dashboard__item">
						<div className="dashboard__item-title">
							{usersTodayAmount.total}
						</div>
						<div className="dashboard__item-text">
							Users Added
						</div>
					</div>
					<div className="dashboard__item">
						<div className="dashboard__item-title">
							{commissionTodayAmount.total}
						</div>
						<div className="dashboard__item-text">
							Total commission
						</div>
					</div>
					<div className="dashboard__item">
						<div className="dashboard__item-title">
							{pendingUsersTodayAmount.total}
						</div>
						<div className="dashboard__item-text">
							Users registration approved
						</div>
					</div>
					<div className="dashboard__item">
						<div className="dashboard__item-title">
							{groupsTodayAmount.total}
						</div>
						<div className="dashboard__item-text">
							Groups created
						</div>
					</div>
					<div className="dashboard__item">
						<div className="dashboard__item-title">
							{usersRegisteredTotal.total}
						</div>
						<div className="dashboard__item-text">
							Amount of Users
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Dashboard };
