import { ProtectedImage } from 'components';
import { ImageContainer } from 'utils/types/common';

export const columns = [
	{
		title: 'Preview',
		dataIndex: 'previewImageId',
		key: 'previewImageId',
		render: (previewImageId: number) =>
			(<ProtectedImage containerType={ImageContainer.PREVIEW_IMAGES} id={previewImageId} />),
	},
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
	},
];
