import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoicesService } from 'services/invoices-service';
import { INVOICES_FILTER_DEFAULT } from 'utils/constants/default-filters';
import {
	GET_ALL_INVOICES,
	UPDATE_INVOICE,
	GET_USERS_INVOICE_HISTORY,
} from 'utils/constants/end-points';
import { FetchStatus } from 'utils/enums/fetch-status';
import { InvoicesFilter, UpdateInvoiceRequest } from 'utils/types/invoices';
import { RequestQuery } from 'utils/types/request-types';
import { InvoicesState } from 'utils/types/store';
import { RootState } from './index';

const initialState: InvoicesState = {
	data: [],
	status: FetchStatus.IDLE,
	filter: INVOICES_FILTER_DEFAULT,
};

export const requestInvoices = createAsyncThunk(
	'invoices/get-all-invoices',
	async (query: RequestQuery) => {
		const fetchInstance = new InvoicesService(GET_ALL_INVOICES);
		const result = await fetchInstance.getInvoices(query);

		return result;
	},
);

export const requestUsersInvoiceHistory = createAsyncThunk(
	'invoices/get-users-invoice-history',
	async (query: RequestQuery) => {
		const fetchInstance = new InvoicesService(GET_USERS_INVOICE_HISTORY);
		const result = await fetchInstance.getUsersInvoiceHIstory(query);

		return result;
	},
);

export const updateInvoice = createAsyncThunk(
	'invoices/update-invoice',
	async (req: UpdateInvoiceRequest) => {
		const fetchInstance = new InvoicesService(UPDATE_INVOICE);
		const { query: { id }, body } = req;
		const result = await fetchInstance.updateInvoice({ id }, body);

		return result;
	},
);

const invoicesSlice = createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		setInvoicesFilter: (state, { payload }: PayloadAction<InvoicesFilter>) => {
			state.filter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestInvoices.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestUsersInvoiceHistory.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestInvoices.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})
			.addCase(requestUsersInvoiceHistory.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

export const { setInvoicesFilter } = invoicesSlice.actions;

const getInvoices = (state: RootState) => state.invoices;

export const getInvoicesData = createSelector(
	getInvoices, invoices => invoices.data,
);
export const getInvoicesFilter = createSelector(getInvoices, invoices => invoices.filter);

export default invoicesSlice.reducer;
