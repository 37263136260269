import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton, Button, ProfileDropdown } from 'components';
import { useAppDispatch } from 'utils/hooks';
import {
	updateMedia,
	requestMedia,
} from 'store/media';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { editMediaValidationSchema } from 'utils/validation/schemes';
import { InputText } from 'components/inputs-formik';
import { toast } from 'react-toastify';
import './EditMedia.scss';

const EditMedia = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id } = useParams<{ id?: string }>();

	type FormValues = {
		title?: string;
		description?: string;
	};

	const [initialValues, setInitialValues] = useState<FormValues>({});

	useEffect(() => {
		if (id) {
			dispatch(requestMedia({ id })).then(({ payload }) => setInitialValues(payload as FormValues));
		}
	}, [dispatch]);

	const handleSubmit = async ({ title, description }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {
		if (id && title && description) {
			const body = {
				title,
				description,
			};

			try {
				await dispatch(updateMedia({ query: { id }, body })).unwrap();
				toast.success('Success!');
				navigate('/content/media/manage-media');
			} catch {
				toast.error('Failed!');
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form className="edit-media__form">
			<InputText name="title" placeholder="Enter media title" />
			<InputText name="description" placeholder="Enter media description" />
			<div className="edit-media__form-footer">
				<Button
					className="btn-purple"
					disabled={!dirty || !isValid || isSubmitting}
					htmlType={HTMLButtonTypes.SUBMIT}
					text="Submit"
				/>
			</div>
		</Form>
	);

	return (
		<div className="edit-media">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Edit media</h1>
				</div>
				<div className="edit-media__wrap">
					<Formik
						component={InnerForm}
						enableReinitialize={true}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={editMediaValidationSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { EditMedia };
