import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { GlossariesService } from 'services/glossaries-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_GLOSSARY,
} from 'utils/constants/end-points';
import { GlossariesState } from 'utils/types/store';
import { GlossaryEnum as ge, GlossaryEnum } from 'utils/types/glossaries';
import { RootState } from './index';

const initialState: GlossariesState = {
	glossaries: {
		[ge.COUNTRIES]: null,
		[ge.LEGAL_NATURE_TYPES]: null,
		[ge.BANKS]: null,
	},
	status: FetchStatus.IDLE,
};

export const requestCountriesGlossary = createAsyncThunk(
	'countries-glossary',
	async () => {
		const fetchInstance = new GlossariesService(GET_GLOSSARY.COUNTRIES);
		const result = await fetchInstance.getGlossary();

		const glossary = ge.COUNTRIES;

		return {
			glossary, result,
		};
	},
);

export const requestLegalNatureTypesGlossary = createAsyncThunk(
	'legal-nature-types-glossary',
	async () => {
		const fetchInstance = new GlossariesService(GET_GLOSSARY.LEGAL_NATURE_TYPES);
		const result = await fetchInstance.getGlossary();

		const glossary = ge.LEGAL_NATURE_TYPES;

		return {
			glossary, result,
		};
	},
);

export const requestBanksGlossary = createAsyncThunk(
	'banks-glossary',
	async () => {
		const fetchInstance = new GlossariesService(GET_GLOSSARY.BANKS);
		const result = await fetchInstance.getGlossary();

		const glossary = ge.BANKS;

		return {
			glossary, result,
		};
	},
);

const glossariesSlice = createSlice({
	name: 'glossaries',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestCountriesGlossary.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestLegalNatureTypesGlossary.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestBanksGlossary.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestCountriesGlossary.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.glossaries[action.payload.glossary] = action.payload.result;
			})
			.addCase(requestLegalNatureTypesGlossary.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.glossaries[action.payload.glossary] = action.payload.result;
			})
			.addCase(requestBanksGlossary.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.glossaries[action.payload.glossary] = action.payload.result;
			});
	},
});

const getGlossaries = (state: RootState) => state.glossaries;

export const getGlossaryData = (glossaryName: GlossaryEnum) => createSelector(
	getGlossaries, glossary => glossary.glossaries[glossaryName],
);

export default glossariesSlice.reducer;
