export const AUTH = 'auth/admin';

// DASHBOARD
export const GET_NEW_USERS_AMOUNT_TODAY = 'users/statistics/number-new-users/today';
export const GET_NEW_REGISTRATION_REQUESTS_AMOUNT_TODAY = 'users/statistics/number-registration-request/today';
export const GET_NEW_GROUPS_AMOUNT_TODAY = 'groups/statistics/number-created-group/today';
export const GET_NEW_SALES_AMOUNT_TODAY = 'sales/statistics/number-sales/today';
export const GET_NEW_COMMISSION_AMOUNT_TODAY = 'transactions/commission-for-today/admin';
export const GET_USERS_REGISTERED_TOTAL = 'users/statistics/number-registered';

// USERS
export const GET_PENDING_USERS = 'users/pending';
export const APPROVE_PENDING_USER = 'users/approve';
export const REJECT_PENDING_USER = 'users/reject';
export const LOCK_PENDING_USER = 'users/lock';
export const UNLOCK_PENDING_USER = 'users/unlock';
export const GET_USERS = 'users/all';

// GROUPS
export const GET_GROUPS = 'groups/all/admin';

// LEADS
export const GET_LEADS = 'leads/all/admin';

// ADMINS
export const CREATE_ADMIN = 'admins';
export const UPDATE_ADMIN = 'admins';
export const GET_ADMIN = 'admins';
export const LOCK_ADMIN = 'admins/lock';
export const UNLOCK_ADMIN = 'admins/unlock';
export const GET_ADMINS = 'admins/all';
export const GET_ADMIN_NAME = 'admins/name';

// DOWNLOAD
export const DOWNLOAD_IMAGE = 'download/image';
export const DOWNLOAD_ATTACHMENT = 'download/attachment';
export const DOWNLOAD_INVOICE = 'download/invoice';
export const DOWNLOAD_DOCUMENT = 'download/document';

// MEDIA
export const GET_ALL_MEDIA = 'medias/all/admin';
export const CREATE_MEDIA = 'medias';
export const UPDATE_MEDIA = 'medias';
export const DELETE_MEDIA = 'medias';
export const GET_MEDIA = 'medias/admin';

// VIDEO
export const UPDATE_VIDEO = 'video';

// UPLOAD
export const UPLOAD_VIDEO = 'upload/video';
export const UPLOAD_IMAGE = 'upload/image';

// TRAININGS
export const ASSIGN_TRAINING_CATEGORY = 'trainings';
export const CREATE_TRAINING = 'trainings';
export const DELETE_TRAINING = 'trainings';
export const UPDATE_TRAINING = 'trainings';
export const GET_TRAINING = 'trainings/admin';
export const GET_TRAININGS = 'trainings/all/admin';

// TRAINING CATEGORIES
export const CREATE_TRAINING_CATEGORY = 'training-categories';
export const GET_TRAINING_CATEGORY = 'training-categories/admin';
export const UPDATE_TRAINING_CATEGORY = 'training-categories';
export const DELETE_TRAINING_CATEGORY = 'training-categories';
export const GET_TRAINING_CATEGORIES = 'training-categories/all/admin';

// COMPLAINTS
export const DELETE_COMPLAINT = 'complaints';
export const APPROVE_COMPLAINT = 'complaints/approve';
export const GET_ALL_COMPLAINTS = 'complaints/all';

// FINANCE
export const GET_TRANSACTIONS_FOR_PERIOD = 'transactions/amount-for-paid';

// BANK ACCOUNTS
export const GET_BANK_ACCOUNT = 'bank-accounts/admin';

// STATISTICS
export const GET_NEW_USERS_AMOUNT = 'users/statistics/number-new-users';
export const GET_NEW_REGISTRATION_REQUESTS_AMOUNT = 'users/statistics/number-registration-request';
export const GET_NEW_SALES_AMOUNT = 'sales/statistics/number-sales';

// SALES
export const GET_ALL_SALES = 'sales/all/admin';
export const SALES_BY_TRANSACTION_CODE = 'sales/child/admin';

// SETTINGS
// export const GET_PERCENT = 'settings/percent';
// export const UPDATE_PERCENT = 'settings/percent';
export const GET_REGISTRATION_FORM_STATUS = 'settings/registration-status';
export const UPDATE_REGISTRATION_FORM_STATUS = 'settings/registration-status';

// TRANSACTIONS
export const GET_TOTAL_PENDING_PAYOUT_AMOUNT = 'transactions/total-pending-payout-amount/admin';
export const GET_TRANSACTIONS_HISTORY = 'transactions/history/admin';

// PIX
export const GET_PIX = 'pix/admin';

// STRIPE
export const MAKE_STRIPE_PAYOUT = 'stripe/payments/payout';
export const GET_STATUS_OF_STRIPE_PAYOUT = 'stripe/payments/status';
export const CANCEL_STRIPE_PAYOUT = 'stripe/payments/cancel/payout';
export const GET_LINK_FOR_STRIPE_PAYOUT = 'stripe/payments/link';

// INVOICES
export const GET_ALL_INVOICES = 'invoice/all/admin';
export const GET_USERS_INVOICE_HISTORY = 'invoice/history';
export const UPDATE_INVOICE = 'invoice';

// DOCUMENTS
export const GET_ALL_DOCUMENTS = 'users-documents/all/admin';
export const GET_DOCUMENT = 'users-documents';
export const UPDATE_DOCUMENT = 'users-documents';

// GLOSSARY
export const GET_GLOSSARY: Record<string, string> = {
	COUNTRIES: 'glossary/countries',
	LEGAL_NATURE_TYPES: 'glossary/legal-nature-types',
	BANKS: 'glossary/banks',
};
