import { BaseEntity, SearchFilter, SortingFilter } from './common';

export type SalesDTO = Array<SaleDTO>;

export type SaleDTO = BaseEntity & {
	hotmartTransactionCode: string;
	purchaseDate: string;
	productName: string;
	price: number;
	clientName: string;
	status: string;
	warrantyDate: string;
	paymentType: string;
	usersId: number;
	userName: string;
	commission: number;
};

export enum SalesListOrderBy {
	PRICE = 'price',
	PURCHASE_DATE = 'purchaseDate',
	WARRANTY_DATE = 'warrantyDate',
}

export interface SalesFilter extends SearchFilter, SortingFilter<SalesListOrderBy> {}
