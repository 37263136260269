import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Button } from 'components';
import { getInvoicesFilter, setInvoicesFilter } from 'store/invoices';
import convertBalance from 'utils/helpers/convert-balance';
import { formatDate } from 'utils/helpers/date-time';
import { getDefaultSortParams, getSortOrder, SorterConfig } from 'utils/helpers/table-sorting';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { useProtectedInvoice } from 'utils/hooks/use-protected-invoice';
import { IdType, Sorting } from 'utils/types/common';
import { InvoiceDTO, InvoicesDTO, InvoicesFilter, InvoicesOrderBy } from 'utils/types/invoices';
import PaymentData from './payment-data';
import './CommissionsTable.scss';

type Props = {
	dataSource: InvoicesDTO;
};

type InvoiceProps = {
	id: IdType;
};

const InvoiceLink = ({ id }: InvoiceProps): JSX.Element => {
	const src = useProtectedInvoice(id);

	return (
		<a href={src} target="_blank">Click to View</a>
	);
};

const CommissionsTable = ({ dataSource }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const invoicesFilter: InvoicesFilter = useAppSelector(getInvoicesFilter);
	const { orderBy, sorting } = invoicesFilter;

	const getConfig = (filterOrderBy: InvoicesOrderBy): SorterConfig<InvoiceDTO> => ({
		...getDefaultSortParams(),
		sortOrder: orderBy === filterOrderBy ? getSortOrder(sorting) : undefined,
		onHeaderCell: () => ({
			onClick: e => {
				e.preventDefault();
				dispatch(setInvoicesFilter({
					...invoicesFilter,
					orderBy: filterOrderBy,
					sorting: sorting === Sorting.ASC ? Sorting.DESC : Sorting.ASC,
				}));
			},
		}),
	});

	const getSorterConfig = (column: keyof InvoiceDTO): SorterConfig<InvoiceDTO> => {
		switch (column) {
			case 'createdAt': {
				return getConfig(InvoicesOrderBy.CREATED);
			}
			case 'name': {
				return getConfig(InvoicesOrderBy.ALPHABET);
			}
			default: return {};
		}
	};

	const columns: ColumnsType<InvoiceDTO> = [
		{
			title: 'User name',
			dataIndex: 'name',
			key: 'name',
			width: 250,
			...getSorterConfig('name'),
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_, record) => (
				<div>
					{formatDate(record.createdAt)}
				</div>
			),
			width: 100,
			...getSorterConfig('createdAt'),
		},
		{
			title: 'Invoice',
			dataIndex: 'id',
			key: 'id',
			render: (id: IdType) => <InvoiceLink id={id} />,
			width: 100,
		},
		{
			title: 'Actual Request Balance',
			dataIndex: 'actualRequestBalance',
			key: 'actualRequestBalance',
			render: (_, record) => (
				<div>
					{convertBalance(record.actualRequestBalance ?? 0)}
				</div>
			),
			width: 150,
		},
		{
			title: 'Current Balance',
			dataIndex: 'currentBalance',
			key: 'currentBalance',
			render: (_, record) => (
				<div>
					{convertBalance(record.currentBalance ?? 0)}
				</div>
			),
			width: 120,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 100,
			render: (_, record) => (
				<Link to={`commissions-history/${record.userId}`}>
					<Button className="btn btn-outline-orange btn-status" text={record.status} />
				</Link>
			),
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			width: 180,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 300,
		},

		{
			title: 'Payment Data',
			dataIndex: 'bankAccountId',
			key: 'bankAccountId',
			render: (_, record) => <PaymentData data={record} />,
			width: 300,
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
			width: 300,
		},
	];

	return (
		<Table
			className="commissions-table"
			columns={columns}
			dataSource={dataSource}
			rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default CommissionsTable;
