import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_TRANSACTIONS_FOR_PERIOD,
} from 'utils/constants/end-points';
import { FinanceState } from 'utils/types/store';
import { RequestQuery } from 'utils/types/request-types';
import { FinanceService } from 'services/finance-service';
import { RootState } from './index';

const initialState: FinanceState = {
	transactionsAmount: {
		amount: null,
	},
	status: FetchStatus.IDLE,
};

export const requestTransactionsAmount = createAsyncThunk(
	'get-transactions-amount',
	async (query: RequestQuery) => {
		const fetchInstance = new FinanceService(GET_TRANSACTIONS_FOR_PERIOD);
		const result = await fetchInstance.getTransactions(query);

		return result;
	},
);

const financeSlice = createSlice({
	name: 'finance',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestTransactionsAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.transactionsAmount = action.payload;
			});
	},
});

const getFinance = (state: RootState) => state.finance;

export const getTransactionsAmount = createSelector(getFinance, finance => finance.transactionsAmount);

export default financeSlice.reducer;
