import { Formik, FormikHelpers, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { Form, Select } from 'formik-antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton, Button, ProfileDropdown } from 'components';
import { InputText } from 'components/inputs-formik';
import { requestTrainingCategory, updateTrainingCategory } from 'store/training-categories';
import { useAppDispatch } from 'utils/hooks';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { UserLevels } from 'utils/enums/user-level';
import { userLevels } from 'utils/constants/user-levels';
import { editCategoryValidationSchema } from 'utils/validation/schemes';
import { arrowDownPurple } from 'assets/images';
import './EditTrainingCategory.scss';

const EditTrainingCategory = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id } = useParams<{ id?: string }>();

	type FormValues = {
		title?: string;
		level: UserLevels;
	};

	const [initialValues, setInitialValues] = useState<FormValues>({
		level: 0,
	});

	useEffect(() => {
		if (id) {
			dispatch(requestTrainingCategory({ id })).then(({ payload }) => setInitialValues(payload as FormValues));
		}
	}, [dispatch]);

	const handleSubmit = async ({ level, title }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {
		if (!isNaN(level) && id && title) {
			const query = { id };
			const body = { title, level };

			try {
				await dispatch(updateTrainingCategory({ query, body })).unwrap();
				toast.success('Success!');
				navigate('/content/trainings/manage-categories');
			} catch {
				toast.error('Failed!');
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form className="edit-category__form">
			<InputText name="title" placeholder="Enter category title" />
			<div className="edit-category__form-footer">
				<Select
					className="level"
					name="level"
					placeholder="Level"
					suffixIcon={<img alt="" src={arrowDownPurple} />}
				>
					{Object.entries(userLevels).map(([key, value]) => (
						<Select.Option key={value} value={value}>
							{key}
						</Select.Option>
					))}
				</Select>
				<Button
					className="btn-purple"
					disabled={!dirty || !isValid || isSubmitting}
					htmlType={HTMLButtonTypes.SUBMIT}
					text="Submit"
				/>
			</div>
		</Form>
	);

	return (
		<div className="edit-category">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Edit training category</h1>
				</div>
				<div className="edit-category__wrap">
					<Formik
						component={InnerForm}
						enableReinitialize={true}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={editCategoryValidationSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { EditTrainingCategory };
