import { logo } from 'assets/images';
import './Navbar.scss';

type Props = {
	menu?: boolean;
};

const Navbar = ({ menu = false }: Props): JSX.Element => (
	<div className="navbar">
		<img
			alt="logo" className="navbar_logo"
			src={logo}
		/>

		{menu && <div className="navbar__menu" />}
	</div>
);

export { Navbar };
