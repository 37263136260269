import { BaseEntity } from './common';

export type DocumentsListDTO = Array<DocumentListItemDTO>;

export type DocumentListItemDTO = BaseEntity & {
	userId: number;
	name: string;
	email: string;
	phone: string;
	documentId: number;
	status: DocumentStatuses;
};

export type DocumentDTO = BaseEntity & {
	status: DocumentStatuses;
	comment: string | null;
};

export type DocumentsList = Array<Document>;

export type Document = {
	name: string;
	email: string;
	phone: string;
	document: {
		id: number;
		status: DocumentStatuses;
	};
};

export type UpdateDocumentDTO = {
	status: string;
	comment: string | null;
};

export type UpdateDocumentRequest = {
	query: {
		id: string;
	};
	body: UpdateDocumentDTO;
};

export enum DocumentStatuses {
	VERIFIED = 'Verified',
	PROCESSING = 'Processing',
	NOT_VERIFIED = 'Not verified',
	NOT_LOADED = 'Not loaded',
}
