import { useState, useEffect } from 'react';
import { getAccessToken } from 'store/auth';
import { BASE_API_URL } from 'utils/constants/base';
import { DOWNLOAD_INVOICE } from 'utils/constants/end-points';
import { RequestMethods } from 'utils/enums/request-methods';
import { useAppSelector } from 'utils/hooks';
import { IdType } from 'utils/types/common';

export const useProtectedInvoice = (id: IdType): string => {
	const token: string | null = useAppSelector(getAccessToken);

	const [src, setSrc] = useState<string>('');

	useEffect(() => {
		let mounted = true;

		if (token) {
			const path =
				`${BASE_API_URL as string}/${DOWNLOAD_INVOICE}?invoiceId=${id}`;

			const options = {
				method: RequestMethods.GET,
				headers: { 'Authorization': `Bearer ${token}` },
			};

			fetch(path, options)
				.then(res => res.blob())
				.then(blob => mounted && setSrc(URL.createObjectURL(blob)));
		}

		return () => {
			mounted = false;
		};
	}, [id, token]);

	return src;
};
