import { FetchAPI } from 'utils/fetch/fetch-api';
import { ApproveComplaintResponse, ComplaintsListDTO, DeleteComplaintResponse } from 'utils/types/complaints';
import { RequestBody, RequestQuery } from 'utils/types/request-types';

class ComplaintsService extends FetchAPI {
	getComplaints = (): Promise<ComplaintsListDTO> => this.get<ComplaintsListDTO>();

	deleteComplaint = (query?: RequestQuery): Promise<DeleteComplaintResponse> => (
		this.delete<DeleteComplaintResponse>(query)
	);

	approveComplaint = (query?: RequestQuery, body?: RequestBody): Promise<ApproveComplaintResponse> => (
		this.patch<ApproveComplaintResponse>(query, body)
	);
}

export { ComplaintsService };
