import { FetchAPI } from 'utils/fetch/fetch-api';
import { RegistrationFormDTO } from 'utils/types/settings';
import { RequestBody, RequestQuery } from 'utils/types/request-types';

class SettingsService extends FetchAPI {
	getRegistrationFormStatus = (): Promise<RegistrationFormDTO> => this.get<RegistrationFormDTO>();

	updateRegistrationFormStatus = (query?: RequestQuery, body?: RequestBody): Promise<RegistrationFormDTO> =>
		this.put<RegistrationFormDTO>(query, body);

	// getPercent = (): Promise<PercentDTO> => this.get<PercentDTO>();

	// updatePercent = (body?: PercentDTO): Promise<PercentDTO> => (
	// 	this.put<PercentDTO>(undefined, body)
	// );
}

export { SettingsService };
