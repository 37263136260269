import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { DashboardService } from 'services/dashboard-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_NEW_USERS_AMOUNT_TODAY,
	GET_NEW_REGISTRATION_REQUESTS_AMOUNT_TODAY,
	GET_NEW_GROUPS_AMOUNT_TODAY,
	GET_NEW_SALES_AMOUNT_TODAY,
	GET_NEW_COMMISSION_AMOUNT_TODAY,
	GET_USERS_REGISTERED_TOTAL,
} from 'utils/constants/end-points';
import { DashboardState } from 'utils/types/store';
import { RootState } from './index';

const initialState: DashboardState = {
	newSalesToday: {
		total: 0,
	},
	newUsersToday: {
		total: 0,
	},
	newPendingUsersToday: {
		total: 0,
	},
	newGroupsToday: {
		total: 0,
	},
	commissionsToday: {
		total: 0,
	},
	usersRegisteredTotal: {
		total: 0,
	},
	status: FetchStatus.IDLE,
};

export const requestNewSalesAmount = createAsyncThunk(
	'dashboard/get-new-sales-amount-today',
	async () => {
		const fetchInstance = new DashboardService(GET_NEW_SALES_AMOUNT_TODAY);
		const result = await fetchInstance.salesAmountToday();

		return result;
	},
);

export const requestNewUsersAmount = createAsyncThunk(
	'dashboard/get-new-users-amount-today',
	async () => {
		const fetchInstance = new DashboardService(GET_NEW_USERS_AMOUNT_TODAY);
		const result = await fetchInstance.usersAmountToday();

		return result;
	},
);

export const requestNewRegistrationRequestsAmount = createAsyncThunk(
	'dashboard/get-new-pending-users-amount-today',
	async () => {
		const fetchInstance = new DashboardService(GET_NEW_REGISTRATION_REQUESTS_AMOUNT_TODAY);
		const result = await fetchInstance.pendingRegistrationsAmountToday();

		return result;
	},
);

export const requestNewGroupsAmount = createAsyncThunk(
	'dashboard/get-new-groups-amount-today',
	async () => {
		const fetchInstance = new DashboardService(GET_NEW_GROUPS_AMOUNT_TODAY);
		const result = await fetchInstance.groupsAmountToday();

		return result;
	},
);

export const requestNewCommissionAmount = createAsyncThunk(
	'dashboard/get-new-commission-amount-today',
	async () => {
		const fetchInstance = new DashboardService(GET_NEW_COMMISSION_AMOUNT_TODAY);
		const result = await fetchInstance.commissionAmountToday();

		return result;
	},
);

export const requestUsersRegisteredTotal = createAsyncThunk(
	'dashboard/get-users-registered-total',
	async () => {
		const fetchInstance = new DashboardService(GET_USERS_REGISTERED_TOTAL);
		const result = await fetchInstance.usersRegisteredTotal();

		return result;
	},
);

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestNewSalesAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewUsersAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewRegistrationRequestsAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewGroupsAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewCommissionAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestUsersRegisteredTotal.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewSalesAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.newSalesToday = action.payload;
			})
			.addCase(requestNewUsersAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.newUsersToday = action.payload;
			})
			.addCase(requestNewRegistrationRequestsAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.newPendingUsersToday = action.payload;
			})
			.addCase(requestNewGroupsAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.newGroupsToday = action.payload;
			})
			.addCase(requestNewCommissionAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.commissionsToday = action.payload;
			})
			.addCase(requestUsersRegisteredTotal.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.usersRegisteredTotal = action.payload;
			});
	},
});

const getDashboardInfo = (state: RootState) => state.dashboard;

export const getNewSalesAmount = createSelector(getDashboardInfo, dashboard => dashboard.newSalesToday);
export const getNewUsersAmount = createSelector(getDashboardInfo, dashboard => dashboard.newUsersToday);
export const getNewPendingUsersAmount = createSelector(getDashboardInfo, dashboard => dashboard.newPendingUsersToday);
export const getNewGroupsAmount = createSelector(getDashboardInfo, dashboard => dashboard.newGroupsToday);
export const getNewCommissionAmount = createSelector(getDashboardInfo, dashboard => dashboard.commissionsToday);
export const getUsersRegisteredTotal = createSelector(getDashboardInfo, dashboard => dashboard.usersRegisteredTotal);

export default dashboardSlice.reducer;
