import { useField } from 'formik';
import { FormItem, Input, InputProps } from 'formik-antd';

type Props = {
	label?: string;
	nullable?: boolean;
	validateStatus?: '' | 'error' | 'success' | 'warning' | 'validating';
	help?: string | number | boolean;
};

const InputText = ({
	name,
	label,
	nullable = true,
	validateStatus,
	help,
	required,
	className,
	onBlur,
	onChange,
	...restProps
}: InputProps & Props): JSX.Element => {
	const [field, meta, actions] = useField(name);

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		nullable && typeof e.target.value === 'string' && actions.setValue(e.target.value.trim() || null);
		onBlur && onBlur(e);
	};

	const validStatus = meta.touched ? (validateStatus || (meta.error ? 'error' : '')) : '';
	const helpMessage = meta.touched && (help || meta.error);

	return (
		<FormItem
			{...{
				name, label, help, required,
			}}
			colon={false}
			help={helpMessage}
			htmlFor={name}
			validateStatus={validStatus}
		>
			<Input
				{...restProps}
				{...field}
				id={name}
				onBlur={handleBlur}
				onChange={onChange}
				value={restProps.value ?? field.value}
			/>
		</FormItem>
	);
};

export { InputText };
