import { UploadAPI } from 'utils/fetch/upload-api';
import { UploadVideoDTO } from 'utils/types/video';
import { UploadImageDTO } from 'utils/types/image';
import { RequestQuery } from 'utils/types/request-types';

class UploadService extends UploadAPI {
	uploadVideo = (body: FormData): Promise<UploadVideoDTO> =>
		this.upload<UploadVideoDTO>(body);

	uploadImage = (body: FormData, query: RequestQuery): Promise<UploadImageDTO> =>
		this.upload<UploadImageDTO>(body, query);
}

export { UploadService };
