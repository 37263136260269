import { Table as AntdTable } from 'antd';
import './Table.scss';

type Props = {
	columns: Array<Column>;
	data: Array<Record<string, unknown>>;
	className?: string;
	scroll?: Record<string, string | number>;
	rowKey?: string;
};

type Column = {
	title: string;
	dataIndex: string;
	key: string;
};

const Table = ({ columns, data, className, scroll, rowKey }: Props): JSX.Element => (
	<div className="table-wrapper">
		<AntdTable
			className={className}
			columns={columns}
			dataSource={data}
			rowKey={rowKey}
			scroll={{ ...scroll, x: true }}
		/>
	</div>
);

export { Table };
