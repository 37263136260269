import { useEffect } from 'react';
import dayjs from 'dayjs';
import { BackButton, Button, Table, ProfileDropdown } from 'components';
import {
	approvePendingUser,
	getPendingUsersList,
	rejectPendingUser,
	requestPendingUsersList,
} from 'store/users';
import { PendingUsersList, PendingUsersListDTO } from 'utils/types/users';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RequestQuery } from 'utils/types/request-types';
import { Country } from 'utils/types/glossaries';
import { getUserLocation } from 'utils/helpers';
import { useAddress } from 'utils/hooks/use-address';
import { checkRoundGreen, crossRoundRed } from 'assets/images';
import { columns } from './ManageRequestsColumns';
import './ManageRequests.scss';

const ManageRequests = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const pendingUsers = useAppSelector<PendingUsersListDTO>(getPendingUsersList);
	const countries: Array<Country> | null = useAddress();

	const Actions = (id: RequestQuery): JSX.Element => (
		<>
			<Button
				icon={<img alt="" src={checkRoundGreen} />}
				onClick={() => dispatch(approvePendingUser(id))}
				text="Accept"
			/>
			<Button
				icon={<img alt="" src={crossRoundRed} />}
				onClick={() => dispatch(rejectPendingUser(id))}
				text="Reject"
			/>
		</>
	);

	const normalize = (coll: PendingUsersListDTO): PendingUsersList => coll.map(({
		id, createdAt, fullName, email, phoneNumber, city, stateId, countryId, refer, company,
	}) => {
		const applied = dayjs(createdAt).format('DD/MM/YYYY');
		const address = getUserLocation({ countryId, stateId, city, countries });

		return {
			key: id,
			applied,
			name: fullName,
			email,
			phone: phoneNumber,
			address,
			refer: refer?.nickname,
			company,
			actions: <Actions id={`${id}`} />,
		};
	});

	useEffect(() => {
		dispatch(requestPendingUsersList());
	}, [dispatch]);

	const normalizedPendingUsers: PendingUsersList = normalize(pendingUsers);

	return (
		<div className="manage-requests">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>List of registration requests</h1>
				</div>

				<div className="manage-requests__table">
					<Table
						columns={columns}
						data={normalizedPendingUsers}
					/>
				</div>
			</div>
		</div>
	);
};

export { ManageRequests };
