import { FetchAPI } from 'utils/fetch/fetch-api';
import { RequestBody, RequestQuery } from 'utils/types/request-types';
import { DocumentDTO, DocumentsListDTO, UpdateDocumentDTO } from 'utils/types/documents';

class DocumentsService extends FetchAPI {
	getDocuments = (query: RequestQuery): Promise<DocumentsListDTO> => this.get<DocumentsListDTO>(query);

	getDocument = (query: RequestQuery): Promise<DocumentDTO> => this.get<DocumentDTO>(query);

	updateDocument = (query: RequestQuery, body: RequestBody): Promise<UpdateDocumentDTO> =>
		this.put<UpdateDocumentDTO>(query, body);
}

export { DocumentsService };
