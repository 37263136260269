import { useEffect, useState } from 'react';
import { BackButton, Button, ProfileDropdown, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RequestQuery } from 'utils/types/request-types';
import {
	deleteTraining,
	getTrainingsList,
	requestTrainings,
} from 'store/trainings';
import { TrainingsList, TrainingsListDTO } from 'utils/types/trainings';
import { Select } from 'antd';
import { UserLevels } from 'utils/enums/user-level';
import { Link } from 'react-router-dom';
import { getTrainingCategoriesList, requestTrainingCategories } from 'store/training-categories';
import { arrowDownPurple, editPurple, deleteOrange } from 'assets/images';
import { columns } from './ManageTrainingsColumns';
import './ManageTrainings.scss';

const ManageTrainings = (): JSX.Element => {
	const { Option } = Select;
	const dispatch = useAppDispatch();
	const trainingsList = useAppSelector<TrainingsListDTO>(getTrainingsList);
	const trainingCategories = useAppSelector(getTrainingCategoriesList);
	const [trainingCategoryFilter, setTrainingCategoryFilter] = useState<number>();
	const Actions = ({ id }: RequestQuery): JSX.Element => (
		<>
			<Link to={`edit/${id}`}>
				<Button className="btn-link" icon={<img alt="" src={editPurple} />} text="Edit" />
			</Link>
			<Button
				className="btn-link"
				icon={<img alt="" src={deleteOrange} />}
				onClick={() => dispatch(deleteTraining({ id }))}
				text="Delete"
			/>
		</>
	);

	const normalize = (coll: TrainingsListDTO): TrainingsList => coll.map(({
		id,
		previewImageId,
		title,
		description,
		link,
		categoryTitle,
		level,
	}) => ({
		key: id,
		previewImageId,
		title,
		description,
		link,
		categoryTitle,
		level: UserLevels[level],
		actions: <Actions id={`${id}`} />,
	}));

	useEffect(() => {
		dispatch(requestTrainingCategories());
	}, [dispatch]);

	useEffect(() => {
		if (trainingCategoryFilter) {
			dispatch(requestTrainings({ categoryId: String(trainingCategoryFilter) }));
		} else {
			dispatch(requestTrainings());
		}
	}, [dispatch, trainingCategoryFilter]);

	const normalizedTrainings: TrainingsList = normalize(trainingsList);

	const handleCategoryFilterChange = (value: number) => {
		setTrainingCategoryFilter(value);
	};

	return (
		<div className="manage-trainings">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="manage-trainings__top">
					<div className="page-title">
						<BackButton />
						<h1>Manage trainings</h1>
					</div>
					<div className="manage-trainings__filter">
						<div className="manage-trainings__filter-title">Filters:</div>
						<Select
							className="manage-trainings__filter-select"
							onChange={handleCategoryFilterChange}
							placeholder="Category"
							suffixIcon={<img alt="" src={arrowDownPurple} />}
						>
							{trainingCategories.map(({ id, title }) => (
								<Option key={id} value={id}>
									{title}
								</Option>
							))}
						</Select>
					</div>
				</div>
				<div className="manage-trainings__wrap">
					<Table
						columns={columns}
						data={normalizedTrainings}
						scroll={{ y: '60vh' }}
					/>
				</div>
			</div>
		</div>
	);
};

export { ManageTrainings };
