import * as Yup from 'yup';
import { usernameValidationRule, passwordValidationRule } from 'utils/validation';
import { messages } from 'utils/validation/validation-messages';
import { AdminRoles } from 'utils/enums/admin-roles';

export const credentialsValidationSchema = Yup.object({
	username: usernameValidationRule.required('Required').nullable(),
	password: passwordValidationRule.required('Required').nullable(),
});

export const forgotPasswordValidationSchema = Yup.object({
	username: usernameValidationRule.required('Required').nullable(),
});

export const setPasswordValidationSchema = Yup.object({
	password: passwordValidationRule.required('Required').nullable(),
	passwordConfirmation: passwordValidationRule.required('Required').nullable()
		.oneOf([Yup.ref('password'), null], messages.password.notMatched),
});

export const addTrainingValidationSchema = Yup.object({
	video: Yup.mixed().test('video', 'Video should be a file', (v) => v instanceof File).required('Video is required!'),
	cover: Yup.mixed().test('cover', 'Cover should be a file', (v) => v instanceof File).required('Cover is required!'),
	title: Yup.string()
		.min(1, messages.video.title.isShort)
		.max(120, messages.video.title.isLong)
		.required('Title is required').nullable(),
	categoryId: Yup.number().required('Choose a category!'),
	description: Yup.string()
		.min(1, messages.video.description.isShort)
		.max(250, messages.video.description.isLong)
		.required('Description is required').nullable(),
	level: Yup.number().required('Choose level!'),
});

export const addMediaValidationSchema = Yup.object({
	video: Yup.mixed().test('video', 'Video should be a file', (v) => v instanceof File).required('Video is required!'),
	cover: Yup.mixed().test('cover', 'Cover should be a file', (v) => v instanceof File).required('Cover is required!'),
	title: Yup.string()
		.min(1, messages.video.title.isShort)
		.max(120, messages.video.title.isLong)
		.required('Title is required').nullable(),
	description: Yup.string()
		.min(1, messages.video.description.isShort)
		.max(250, messages.video.description.isLong)
		.required('Description is required').nullable(),
});

export const editTrainingValidationSchema = Yup.object({
	title: Yup.string()
		.min(1, messages.video.title.isShort)
		.max(120, messages.video.title.isLong)
		.required('Title is required').nullable(),
	description: Yup.string()
		.min(1, messages.video.description.isShort)
		.max(250, messages.video.description.isLong)
		.required('Description is required').nullable(),
	categoryId: Yup.number().required('Choose a category!'),
	level: Yup.number().required('Choose level!'),
});

export const editMediaValidationSchema = Yup.object({
	title: Yup.string()
		.min(1, messages.video.title.isShort)
		.max(120, messages.video.title.isLong)
		.required('Title is required').nullable(),
	description: Yup.string()
		.min(1, messages.video.description.isShort)
		.max(250, messages.video.description.isLong)
		.required('Description is required').nullable(),
});

export const addCategoryValidationSchema = Yup.object({
	title: Yup.string()
		.min(1, messages.video.title.isShort)
		.max(120, messages.video.title.isLong)
		.required('Title is required').nullable(),
	level: Yup.number().required('Choose a level!'),
});

export const editCategoryValidationSchema = Yup.object({
	title: Yup.string()
		.min(1, messages.video.title.isShort)
		.max(120, messages.video.title.isLong)
		.required('Title is required').nullable(),
	level: Yup.number().required('Choose a level!'),
});

export const addAdminValidationSchema = Yup.object({
	fullName: Yup.string()
		.min(3, messages.name.isShort)
		.max(64, messages.name.isLong)
		.required('Name is required').nullable(),
	email: usernameValidationRule.required('Required').nullable(),
	password: passwordValidationRule.required('Required').nullable(),
	role: Yup.string().oneOf(Object.values(AdminRoles)),
});

export const editAdminValidationSchema = Yup.object({
	fullName: Yup.string()
		.min(3, messages.name.isShort)
		.max(64, messages.name.isLong)
		.required('Name is required').nullable(),
	email: usernameValidationRule.required('Required').nullable(),
	password: passwordValidationRule.required('Required').nullable(),
	role: Yup.string().oneOf(Object.values(AdminRoles)),
});

export const editSettingsSchema = Yup.object({
	registrationStatus: Yup.string()
		.required('Status is required').nullable(),
	// percent: Yup.number().positive().max(100)
	// 	.required('Percent is required').nullable(),
});
