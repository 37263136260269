import { Button, ProfileDropdown } from 'components';
import { Link } from 'react-router-dom';
import {
	usersBtnIco2,
	usersBtnIco3,
	usersBtnIco4,
	usersBtnIco5,
} from 'assets/images';
import './Users.scss';

const Users = (): JSX.Element => (
	<div className="users">
		<div className="header">
			<ProfileDropdown />
		</div>

		<div className="container">
			<div className="page-title">
				<h1>Users</h1>
			</div>

			<div className="users__wrap">
				<div className="users__item">
					<div className="card">
						<div className="page-block-title">New users registration</div>
						<div className="card-body">
							<Link to="manage-requests">
								<Button
									className="btn-link users__item-btn-purple"
									icon={<img alt="" src={usersBtnIco2} />}
									text="Manage requests"
								/>
							</Link>
						</div>
					</div>
				</div>

				<div className="users__item">
					<div className="card">
						<div className="page-block-title">User account management</div>
						<div className="card-body">
							<Link to="users-list">
								<Button
									className="btn-link users__item-btn-orange"
									icon={<img alt="" src={usersBtnIco3} />}
									text="Users list"
								/>
							</Link>
							<Link to="groups-list">
								<Button
									className="btn-link users__item-btn-orange"
									icon={<img alt="" src={usersBtnIco4} />}
									text="Groups list"
								/>
							</Link>
							<Link to="leads-base">
								<Button
									className="btn-link users__item-btn-orange"
									icon={<img alt="" src={usersBtnIco5} />}
									text="Leads base"
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export { Users };

