import { FetchAPI } from 'utils/fetch/fetch-api';
import { RequestBody, RequestQuery } from 'utils/types/request-types';
import { InvoiceListItemsDTO, InvoicesDTO, UpdateInvoiceDTO } from 'utils/types/invoices';

class InvoicesService extends FetchAPI {
	getInvoices = (query: RequestQuery): Promise<InvoicesDTO> => this.get<InvoicesDTO>(query);

	getUsersInvoiceHIstory = (query: RequestQuery): Promise<InvoiceListItemsDTO> =>
		this.get<InvoiceListItemsDTO>(query);

	updateInvoice = (query: RequestQuery, body: RequestBody): Promise<UpdateInvoiceDTO> =>
		this.put<UpdateInvoiceDTO>(query, body);
}

export { InvoicesService };
