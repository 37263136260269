import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	RequestQuery,
	UploadImageRequestBody, UploadVideoRequestBody,
} from 'utils/types/request-types';
import {
	UPLOAD_VIDEO,
	UPLOAD_IMAGE,
} from 'utils/constants/end-points';
import { UploadService } from 'services/upload-service';

export const uploadVideo = createAsyncThunk(
	'upload-video',
	async (body: UploadVideoRequestBody) => {
		const fetchInstance = new UploadService(UPLOAD_VIDEO);
		const { video, title } = body;
		const newTitle: string = title ? title : video.name ;

		const formData = new FormData();

		formData.append('file', video, newTitle);

		const result = fetchInstance.uploadVideo(formData);

		return result;
	},
);

export const uploadImage = createAsyncThunk(
	'upload-image',
	async (body: UploadImageRequestBody) => {
		const fetchInstance = new UploadService(UPLOAD_IMAGE);
		const { cover } = body;
		const query: RequestQuery = {
			type: 'preview-images',
		};

		const formData = new FormData();

		formData.append('file', cover);
		const result = fetchInstance.uploadImage(formData, query);

		return result;
	},
);
