import { useState, useEffect } from 'react';
import { getAccessToken } from 'store/auth';
import { ImageContainer } from 'utils/types/common';
import { BASE_API_URL } from 'utils/constants/base';
import { DOWNLOAD_IMAGE } from 'utils/constants/end-points';
import { useAppSelector } from 'utils/hooks';
import { makeAuthHeader } from 'utils/helpers';

export const useProtectedImage = (
	imageId: number, containerName: ImageContainer,
): string => {
	const token: string | null = useAppSelector(getAccessToken);
	const authorizationHeader: Record<string, string> = makeAuthHeader(token as string);

	const [src, setSrc] = useState<string>('');

	useEffect(() => {
		let mounted = true;

		if (token) {

			const path =
				`${BASE_API_URL as string}/${DOWNLOAD_IMAGE}?imageId=${imageId}&containerName=${containerName}`;

			const options = {
				method: 'GET',
				headers: authorizationHeader,
			};

			fetch(path, options)
				.then(res => res.blob())
				.then(blob => mounted && setSrc(URL.createObjectURL(blob)));
		}

		return () => {
			mounted = false;
		};

	}, [containerName, imageId, token]);

	return src;
};
