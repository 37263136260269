import { AdditionalInfoModal, BankAccountModal, Button, ContactDetailsModal, PixModal } from 'components';
import { useWindow } from 'utils/hooks';
import { UserDTO } from 'utils/types/users';
import './UserAdditionalInfo.scss';

type Props = { user: UserDTO };

const UserAdditionalInfo = ({ user }: Props): JSX.Element | null => {
	const [additionalInfoModal, showAdditionalInfoModal, hideAdditionalInfoModal] = useWindow();
	const [contactDetailsModal, showContactDetailsModal, hideContactDetailsModal] = useWindow();
	const [bankAccountModal, showBankAccountModal, hideBankAccountModal] = useWindow();
	const [pixModal, showPixModal, hidePixModal] = useWindow();

	return (
		<div className="user-additional-info">
			<Button
				className="btn-outline btn-outline-orange"
				onClick={showContactDetailsModal}
				text="Contact details"
			/>

			{user.bankAccountId && (
				<Button
					className="btn-outline btn-outline-orange"
					onClick={showBankAccountModal}
					text="Bank account details"
				/>
			)}

			{user.pixId && (
				<Button
					className="btn-outline btn-outline-orange"
					onClick={showPixModal}
					text="PIX details"
				/>
			)}

			<Button
				className="btn-outline btn-outline-orange"
				onClick={showAdditionalInfoModal}
				text="Additional information"
			/>

			{contactDetailsModal && <ContactDetailsModal data={user} onClose={hideContactDetailsModal} />}

			{bankAccountModal && user.bankAccountId && (
				<BankAccountModal bankAccountId={user.bankAccountId} onClose={hideBankAccountModal} />
			)}

			{pixModal && user.pixId && (
				<PixModal onClose={hidePixModal} pixId={user.pixId} />
			)}

			{additionalInfoModal && <AdditionalInfoModal data={user} onClose={hideAdditionalInfoModal} />}
		</div>
	);
};

export default UserAdditionalInfo;
