import { Sorting } from 'utils/types/common';
import { InvoicesFilter, InvoicesOrderBy } from 'utils/types/invoices';
import { SalesFilter, SalesListOrderBy } from 'utils/types/sales';
import { UsersFilter } from 'utils/types/users';

export const INVOICES_FILTER_DEFAULT: InvoicesFilter = {
	acceptedStatus: true,
	analysisStatus: true,
	orderBy: InvoicesOrderBy.CREATED,
	rejectedStatus: true,
	search: '',
	sorting: Sorting.DESC,
};

export const SALES_FILTER_DEFAULT: SalesFilter = {
	orderBy: SalesListOrderBy.PURCHASE_DATE,
	search: '',
	sorting: Sorting.DESC,
};

export const USERS_FILTER_DEFAULT: UsersFilter = {
	invoicingPermissions: false,
	isReferral: false,
	search: '',
};
