import { FetchAPI } from 'utils/fetch/fetch-api';
import { RequestQuery } from 'utils/types/request-types';
import { TotalPendingPayoutAmountDTO, TransactionsHistoryDTO } from 'utils/types/transactions';

class TransactionsService extends FetchAPI {
	getTotalPendingPayoutAmount = (): Promise<TotalPendingPayoutAmountDTO> => this.get<TotalPendingPayoutAmountDTO>();

	getTransactionsHistory = (query: RequestQuery): Promise<TransactionsHistoryDTO> =>
		this.get<TransactionsHistoryDTO>(query);
}

export { TransactionsService };
