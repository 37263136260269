import { useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { BackButton, Button, Checkbox, ProfileDropdown, SearchPanel } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { UsersFilter, UsersListDTO } from 'utils/types/users';
import { getUsersList, requestUsersList, getUsersFilter, setUsersFilter } from 'store/users';
import { makeQuery } from 'utils/helpers';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import UsersTable from './users-table';
import './UsersList.scss';

type FormValues = Pick<UsersFilter, 'isReferral' | 'invoicingPermissions'>;

type FilterProps = {
	initialValues: FormValues;
	onSubmit: (values: FormValues) => void;
};

const FiltersForm = (): JSX.Element => (
	<Form className="filters-form">
		<Checkbox label="Is Referral:" name="isReferral" />
		<Checkbox label="Has Invoicing Permissions:" name="invoicingPermissions" />
		<Button htmlType={HTMLButtonTypes.SUBMIT} text="Submit" />
	</Form>
);

const UserFilters = ({ initialValues, onSubmit }: FilterProps): JSX.Element => (
	<Formik
		component={FiltersForm}
		initialValues={initialValues}
		onSubmit={onSubmit}
	/>
);

const UsersList = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const usersList = useAppSelector<UsersListDTO>(getUsersList);
	const usersFilter: UsersFilter = useAppSelector(getUsersFilter);

	useEffect(() => {
		dispatch(requestUsersList(makeQuery({ ...usersFilter })));
	}, [dispatch, usersFilter]);

	const handleSearch = (search: string): void => {
		dispatch(setUsersFilter({ ...usersFilter, search }));
	};

	const handleSubmit = (values: FormValues) => {
		dispatch(setUsersFilter({ ...usersFilter, ...values }));
	};

	return (
		<div className="users-list">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="users-list__top">
					<div className="page-title">
						<BackButton />
						<h1>Users List</h1>
					</div>

					<div className="users-filters">
						<SearchPanel initialValue={usersFilter.search} onSearch={handleSearch} />

						<UserFilters
							initialValues={
								{
									isReferral: usersFilter.isReferral,
									invoicingPermissions: usersFilter.invoicingPermissions,
								}
							}
							onSubmit={handleSubmit}
						/>
					</div>
				</div>

				<UsersTable dataSource={usersList} />
			</div>
		</div>
	);
};

export { UsersList };
