import { BackButton, Button, ProfileDropdown } from 'components';
import { InputText } from 'components/inputs-formik';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { useAppDispatch } from 'utils/hooks';
import {
	createMedia,
} from 'store/media';
import { uploadVideo, uploadImage } from 'store/upload';
import { Upload } from 'antd';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { assignPreviewImage } from 'store/video';
import { addMediaValidationSchema } from 'utils/validation/schemes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addMediaIco, uploadCover } from 'assets/images';
import './AddMedia.scss';

const AddMedia = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type FormValues = {
		video?: File;
		cover?: File;
		title?: string;
		description?: string;
	};

	const initialValues: FormValues = {};

	const handleSubmit = async ({ video, cover, title, description }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {
		if (video && cover && title && description) {
			const id = toast.loading('Please wait...');

			try {
				const { videoId } = await dispatch(uploadVideo({ video, title })).unwrap();
				const { imageId } = await dispatch(uploadImage({ cover })).unwrap();

				await dispatch(assignPreviewImage({ videoId, imageId })).unwrap();

				await dispatch(createMedia({ videoId, title, description })).unwrap();

				toast.update(id, { render: 'Success!', type: 'success', isLoading: false, autoClose: 5000 });
				navigate('/content/media/manage-media');
			} catch {
				toast.update(id, { render: 'Failed!', type: 'error', isLoading: false, autoClose: 5000 });
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ setFieldValue, dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form className="add-media__form">
			<div className="add-media__form-btns">
				<Upload
					accept={'video/*'}
					beforeUpload={() => false}
					maxCount={1}
					onChange={({ file }) => setFieldValue('video', file)}
				>
					<Button className="btn-purple" icon={<img alt="" src={addMediaIco} />} text="Upload a video" />
				</Upload>
				<Upload
					accept={'image/png'}
					beforeUpload={() => false}
					maxCount={1}
					onChange={({ file }) => setFieldValue('cover', file)}
				>
					<Button
						className="btn-purple"
						icon={<img alt="" src={uploadCover} />}
						text="Upload a media cover"
					/>
				</Upload>
			</div>
			<InputText name="title" placeholder="Enter media title" />
			<InputText name="description" placeholder="Enter media description" />
			<div className="add-media__form-footer">
				<Button
					className="btn-purple"
					disabled={!dirty || !isValid || isSubmitting}
					htmlType={HTMLButtonTypes.SUBMIT}
					text="Submit"
				/>
			</div>
		</Form>
	);

	return (
		<div className="add-media">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Add a new media</h1>
				</div>
				<div className="add-media__wrap">
					<Formik
						component={InnerForm}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={addMediaValidationSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { AddMedia };
