import { FetchAPI } from 'utils/fetch/fetch-api';
import { UpdateVideoDTO } from 'utils/types/video';
import { RequestBody, RequestQuery } from 'utils/types/request-types';

class VideoService extends FetchAPI {
	assignPreviewImage = (query: RequestQuery, body?: RequestBody): Promise<UpdateVideoDTO> =>
		this.put<UpdateVideoDTO>(query, body);
}

export { VideoService };
