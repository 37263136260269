import { BackButton, Button, ProfileDropdown } from 'components';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form, Select } from 'formik-antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'utils/hooks';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { editAdminValidationSchema } from 'utils/validation/schemes';
import { InputText } from 'components/inputs-formik';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminRoles } from 'utils/enums/admin-roles';
import { requestAdmin, updateAdmin } from 'store/admins';
import { toast } from 'react-toastify';
import './EditAdmin.scss';

const EditAdmin = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id } = useParams<{ id?: string }>();

	type FormValues = {
		fullName?: string;
		email?: string;
		password?: string;
		role: AdminRoles;
	};

	const [initialValues, setInitialValues] = useState<FormValues>({
		role: AdminRoles.GENERAL,
	});

	useEffect(() => {
		if (id) {
			dispatch(requestAdmin({ id })).then(({ payload }) => setInitialValues(payload as FormValues));
		}
	}, [dispatch]);

	const handleSubmit = async ({ fullName, email, password, role }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {
		if (id && fullName && email && password && role) {
			try {
				await dispatch(updateAdmin({
					query: { id },
					body: { fullName, email, password, role },
				})).unwrap();

				toast.success('Success!');
				navigate('/admins');
			} catch {
				toast.error('Failed!');
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form className="edit-admin__form">
			<InputText name="fullName" placeholder="Enter name" />
			<InputText name="email" placeholder="Enter email" />
			<InputText name="password" placeholder="Enter password" />
			<div className="edit-admin__form-footer">
				<Select
					className="role"
					name="role"
					placeholder="Role"
				>
					{Object.values(AdminRoles).map((item) => (
						<Select.Option key={item} value={item}>
							{item}
						</Select.Option>
					))}
				</Select>
				<Button
					className="btn-purple"
					disabled={!dirty || !isValid || isSubmitting}
					htmlType={HTMLButtonTypes.SUBMIT}
					text="Submit"
				/>
			</div>
		</Form>
	);

	return (
		<div className="edit-admin">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<BackButton />
					<h1>Edit admin</h1>
				</div>
				<div className="edit-admin__wrap">
					<Formik
						component={InnerForm}
						enableReinitialize={true}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={editAdminValidationSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { EditAdmin };
