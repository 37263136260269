import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AdminProfileService } from 'services/admin-profile-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_ADMIN_NAME,
} from 'utils/constants/end-points';
import { AdminProfileState } from 'utils/types/store';
import { RootState } from './index';

const initialState: AdminProfileState = {
	name: 'Admin',
	status: FetchStatus.IDLE,
};

export const requestAdminName = createAsyncThunk(
	'admin-name',
	async () => {
		const fetchInstance = new AdminProfileService(GET_ADMIN_NAME);
		const result = await fetchInstance.name();

		return result;
	},
);

const adminProfileSlice = createSlice({
	name: 'admin-profile',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestAdminName.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestAdminName.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.name = action.payload.name;
			});
	},
});

const getAdminProfile = (state: RootState) => state.adminProfile;

export const getAdminName = createSelector(getAdminProfile, adminProfile => adminProfile.name);

export default adminProfileSlice.reducer;
