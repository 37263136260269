import { useEffect } from 'react';
import { BackButton, Table, ProfileDropdown, Button, InputText, Checkbox } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { LeadsList, LeadsListDTO } from 'utils/types/leads';
import { getLeadsList, requestLeadsList } from 'store/leads';
import { Row } from 'antd';
import { Form, Select } from 'formik-antd';
import { Formik } from 'formik';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { makeQuery } from 'utils/helpers';
import { searchIcoWhite, arrowDownPurple } from 'assets/images';
import { columns } from './LeadsListColumns';
import './LeadsBase.scss';

const LeadsBase = (): JSX.Element => {
	const { Option } = Select;
	const dispatch = useAppDispatch();
	const leadsList = useAppSelector<LeadsListDTO>(getLeadsList);

	type FormValues = {
		orderBy: string;
		sorting: string;
		search?: string;
		contactedStatus: boolean;
		inNegotiationsStatus: boolean;
		postponedStatus: boolean;
		linkSentStatus: boolean;
		soldStatus: boolean;
		lostStatus: boolean;
		newLeadStatus: boolean;
	};

	const initialValues: FormValues = {
		orderBy: 'created',
		sorting: 'ASC',
		contactedStatus: true,
		inNegotiationsStatus: true,
		postponedStatus: true,
		linkSentStatus: true,
		soldStatus: true,
		lostStatus: true,
		newLeadStatus: true,
	};

	const handleSubmit = (values: FormValues) => {
		dispatch(requestLeadsList(makeQuery(values)));
	};

	const normalize = (coll: LeadsListDTO): LeadsList => coll.map(({
		id,
		name,
		email,
		phoneNumber,
		status,
		comment,
		userName,
	}) => ({
		key: id,
		name,
		email,
		phoneNumber,
		status,
		comment,
		userName,
	}));

	const InnerForm = (): JSX.Element => (
		<Form>
			<div className="leads-base__filter">
				<div className="leads-base__filter-search">
					<InputText name="search" placeholder="Enter search query" />
					<div className="leads-base__filter-search-btn">
						<img alt="" src={searchIcoWhite} />
					</div>
				</div>
				<Select
					defaultValue="ASC"
					name="sorting"
					suffixIcon={<img alt="" src={arrowDownPurple} />}
				>
					<Option value="ASC">Ascending</Option>
					<Option value="DESC">Descending</Option>
				</Select>
				<Select
					defaultValue="created"
					name="orderBy"
					suffixIcon={<img alt="" src={arrowDownPurple} />}
				>
					<Option value="created">Created</Option>
					<Option value="alphabet">Alphabetically</Option>
				</Select>
			</div>
			<Row justify="start">
				<Checkbox label="Contacted:" name="contactedStatus" />
				<Checkbox label="In Negotiations:" name="inNegotiationsStatus" />
				<Checkbox label="Postponed:" name="postponedStatus" />
				<Checkbox label="Link Sent:" name="linkSentStatus" />
				<Checkbox label="Sold:" name="soldStatus" />
				<Checkbox label="Lost:" name="lostStatus" />
				<Checkbox label="New Lead:" name="newLeadStatus" />
				<Button htmlType={HTMLButtonTypes.SUBMIT} text="Submit" />
			</Row>
		</Form>
	);

	useEffect(() => {
		dispatch(requestLeadsList(makeQuery(initialValues)));
	}, [dispatch]);

	const normalizedLeads: LeadsList = normalize(leadsList);

	return (
		<div className="leads-base">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="leads-base__top">
					<div className="page-title">
						<BackButton />
						<h1>Leads base</h1>
					</div>

					<div className="leads-base__filter">
						<Formik
							component={InnerForm}
							initialValues={initialValues}
							onSubmit={handleSubmit}
						/>
					</div>
				</div>

				<div className="leads-base__table">
					<Table
						columns={columns}
						data={normalizedLeads}
					/>
				</div>
			</div>
		</div>
	);
};

export { LeadsBase };
