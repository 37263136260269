import { Switch as AntdSwitch, FormItem, SwitchProps } from 'formik-antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import './Switch.scss';

type Props = {
	checked?: boolean;
	onChange?: SwitchChangeEventHandler;
	name: string;
};

const Switch = ({ onChange, name, ...restProps }: Props & SwitchProps): JSX.Element => (
	<div className="switch">
		<FormItem
			{...{
				name,
			}}
			colon={false}
			htmlFor={name}
		>
			<AntdSwitch {...restProps} name={name} onChange={onChange} />
		</FormItem>
	</div>
);

export { Switch };
