import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { requestBankAccount } from 'store/bank-accounts';
import { makeQuery } from 'utils/helpers';
import { useAppDispatch, useBanks } from 'utils/hooks';
import { BankAccountDTO } from 'utils/types/bank-accounts';
import { IdType } from 'utils/types/common';
import { Bank } from 'utils/types/glossaries';

type Props = {
	bankAccountId: IdType;
	onClose: () => void;
};

const BankAccountModal = ({ bankAccountId, onClose }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const [bankAccountData, setBankAccountData] = useState<BankAccountDTO | null>(null);
	const banks: Bank[] | null = useBanks();

	useEffect(() => {
		if (!bankAccountData) {
			dispatch(requestBankAccount(makeQuery({ id: bankAccountId }))).then((res) => {
				setBankAccountData(res.payload as BankAccountDTO);
			});
		}
	}, [bankAccountId, bankAccountData, dispatch]);

	if (!bankAccountData || !banks) return null;

	const { accountNumber, agency, bankId, cpf } = bankAccountData;

	return (
		<Modal
			centered
			footer={null}
			onCancel={onClose}
			title="Bank Account details"
			visible
		>
			<div>
				<p>
					Bank:
					{' '}
					{banks.find(item => item.id === bankId)?.name ?? 'No data'}
				</p>
				<p>
					Agency:
					{' '}
					{agency}
				</p>
				<p>
					Account number:
					{' '}
					{accountNumber}
				</p>
				<p>
					CPF:
					{' '}
					{cpf}
				</p>
			</div>
		</Modal>
	);
};

export default BankAccountModal;
