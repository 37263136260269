import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesService } from 'services/sales-service';
import { SALES_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { GET_ALL_SALES } from 'utils/constants/end-points';
import { FetchStatus } from 'utils/enums/fetch-status';
import { RequestQuery } from 'utils/types/request-types';
import { SalesFilter } from 'utils/types/sales';
import { SalesState } from 'utils/types/store';
import { RootState } from './index';

const initialState: SalesState = {
	data: [],
	status: FetchStatus.IDLE,
	filter: SALES_FILTER_DEFAULT,
};

export const requestSales = createAsyncThunk(
	'sales/get-all-sales',
	async (query: RequestQuery) => {
		const fetchInstance = new SalesService(GET_ALL_SALES);
		const result = await fetchInstance.getSales(query);

		return result;
	},
);

const salesListSlice = createSlice({
	name: 'sales',
	initialState,
	reducers: {
		setSalesListFilter: (state, { payload }: PayloadAction<SalesFilter>) => {
			state.filter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestSales.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestSales.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

export const { setSalesListFilter } = salesListSlice.actions;

const getSales = (state: RootState) => state.sales;

export const getSalesData = createSelector(getSales, sales => sales.data);
export const getSalesFilter = createSelector(getSales, sales => sales.filter);

export default salesListSlice.reducer;
