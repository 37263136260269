import { useEffect } from 'react';
import { BackButton, Button, ProfileDropdown, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RequestQuery } from 'utils/types/request-types';
import { checkRoundGreen, crossRoundRed } from 'assets/images';
import { approveComplaint, deleteComplaint, getComplaintsList, requestComplaintsList } from 'store/complaints';
import { ComplaintsList as ComplaintsListType, ComplaintsListDTO } from 'utils/types/complaints';
import { columns } from './ComplaintsListColumns';
import './ComplaintsList.scss';

const ComplaintsList = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const complaintsList = useAppSelector<ComplaintsListDTO>(getComplaintsList);

	interface ActionsProps {
		id: RequestQuery;
	}

	const Actions = ({ id }: ActionsProps): JSX.Element => (
		<>
			<Button
				icon={<img alt="" src={checkRoundGreen} />}
				onClick={() => dispatch(approveComplaint(id))}
				text="Approve"
			/>
			<Button
				icon={<img alt="" src={crossRoundRed} />}
				onClick={() => dispatch(deleteComplaint(id))}
				text="Delete"
			/>
		</>
	);

	const normalize = (coll: ComplaintsListDTO): ComplaintsListType => coll.map(({
		id,
		userId,
		userAvatarImageId,
		groupId,
		groupAvatarImageId,
		userImages,
		groupImages,
		isIndecent,
		isRude,
		isSensitive,
		description,
	}) => ({
		key: id,
		userId,
		userAvatarImageId,
		groupId,
		groupAvatarImageId,
		userImages,
		groupImages,
		isIndecent,
		isRude,
		isSensitive,
		description,
		actions: <Actions id={{ id: `${id}` }} />,
	}));

	useEffect(() => {
		dispatch(requestComplaintsList());
	}, [dispatch]);

	const normalizedComplaints: ComplaintsListType = normalize(complaintsList);

	return (
		<div className="complaints-list">
			<div className="row header">
				<BackButton />
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="container-item">
					<div className="container-item__header">
						<h2 className="container-item__header-text">COMPLAINTS LIST</h2>
					</div>
					<div className="container-item__body">
						<Table
							columns={columns}
							data={normalizedComplaints}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ComplaintsList };
