import { createAsyncThunk } from '@reduxjs/toolkit';
import { VideoService } from 'services/video-service';
import {
	AssignPreviewImageRequestBody,
} from 'utils/types/request-types';
import {
	UPDATE_VIDEO,
} from 'utils/constants/end-points';

export const assignPreviewImage = createAsyncThunk(
	'assign-video-cover',
	async (reqBody: AssignPreviewImageRequestBody) => {
		const { videoId, imageId } = reqBody;
		const fetchInstance = new VideoService(UPDATE_VIDEO);

		const query = { id: String(videoId) };
		const body = { previewImageId: imageId };

		await fetchInstance.assignPreviewImage(query, body);

		return;
	},
);
