export const columns = [
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
	},
	{
		title: 'Level',
		dataIndex: 'level',
		key: 'level',
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
	},
];
