import { BackButton, Button, ProfileDropdown } from 'components';
import { Link } from 'react-router-dom';
import { addMediaIco, manageMediaIco, boardWhite } from 'assets/images';
import './Trainings.scss';

const Trainings = (): JSX.Element => (
	<div className="trainings">
		<div className="row header">
			<ProfileDropdown />
		</div>

		<div className="container">
			<div className="page-title">
				<BackButton />
				<h1>Trainings</h1>
			</div>
			<div className="trainings__wrap">
				<Link to="add-training">
					<Button className="btn-purple" icon={<img alt="" src={addMediaIco} />} text="Add training" />
				</Link>
				<Link to="manage-trainings">
					<Button className="btn-purple" icon={<img alt="" src={boardWhite} />} text="Manage trainings" />
				</Link>
				<Link to="manage-categories">
					<Button
						className="btn-purple"
						icon={<img alt="" src={manageMediaIco} />}
						text="Manage categories"
					/>
				</Link>
			</div>
		</div>
	</div>
);

export { Trainings };
