export enum ImageContainer {
	GROUP_AVATAR = 'group-avatar',
	GROUP_IMAGES = 'group-images',
	USER_AVATAR = 'user-avatar',
	USER_IMAGES = 'user-images',
	PREVIEW_IMAGES = 'preview-images',
}

export enum Sorting {
	ASC = 'ASC',
	DESC = 'DESC',
}

export type BaseEntity = {
	id: IdType;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
};

export type IdType = number;

export type ResultFailure = Error | null;

export interface SearchFilter {
	search: string;
}

export interface SortingFilter<T> {
	sorting: Sorting;
	orderBy: T;
}

export interface WithFilter<T> {
	filter: T;
}
