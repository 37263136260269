import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { StatisticsService } from 'services/statistics-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import {
	GET_NEW_USERS_AMOUNT,
	GET_NEW_REGISTRATION_REQUESTS_AMOUNT,
	GET_NEW_SALES_AMOUNT,
} from 'utils/constants/end-points';
import { StatisticsState } from 'utils/types/store';
import { RootState } from './index';

const initialState: StatisticsState = {
	newUsersAmount: {
		week: [0],
		month: [0],
		year: [0],
	},
	newRegistrationRequestsAmount: {
		week: [0],
		month: [0],
		year: [0],
	},
	newSalesAmount: {
		week: [0],
		month: [0],
		year: [0],
	},
	status: FetchStatus.IDLE,
};

export const requestNewSalesAmount = createAsyncThunk(
	'statistics/new-sales',
	async () => {
		const fetchInstance = new StatisticsService(GET_NEW_SALES_AMOUNT);
		const result = await fetchInstance.newSalesAmount();

		return result;
	},
);

export const requestNewUsersAmount = createAsyncThunk(
	'statistics/new-users',
	async () => {
		const fetchInstance = new StatisticsService(GET_NEW_USERS_AMOUNT);
		const result = await fetchInstance.newUsersAmount();

		return result;
	},
);

export const requestNewRegistrationRequestsAmount = createAsyncThunk(
	'statistics/new-registration-requests',
	async () => {
		const fetchInstance = new StatisticsService(GET_NEW_REGISTRATION_REQUESTS_AMOUNT);
		const result = await fetchInstance.newRegistrationRequestsAmount();

		return result;
	},
);

const statisticsSlice = createSlice({
	name: 'statistics',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestNewSalesAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewUsersAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewRegistrationRequestsAmount.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestNewSalesAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.newSalesAmount = action.payload;
			})
			.addCase(requestNewUsersAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.newUsersAmount = action.payload;
			})
			.addCase(requestNewRegistrationRequestsAmount.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.newRegistrationRequestsAmount = action.payload;
			});
	},
});

const getStatistics = (state: RootState) => state.statistics;

export const getNewSalesAmount = createSelector(getStatistics, statistics => statistics.newSalesAmount);
export const getNewUsersAmount = createSelector(getStatistics, statistics => statistics.newUsersAmount);
export const getNewRegistrationRequestsAmount = createSelector(getStatistics, statistics =>
	(statistics.newRegistrationRequestsAmount));

export default statisticsSlice.reducer;
