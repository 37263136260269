import { FetchAPI } from 'utils/fetch/fetch-api';
import { RequestBody, RequestQuery } from 'utils/types/request-types';
import {
	DeleteTrainingCategoryDTO,
	TrainingCategoriesListDTO,
	TrainingCategoryDTO,
} from 'utils/types/training-categories';

class TrainingCategoriesService extends FetchAPI {
	getTrainingCategories = (): Promise<TrainingCategoriesListDTO> => this.get<TrainingCategoriesListDTO>();

	getTrainingCategory = (query: RequestQuery): Promise<TrainingCategoryDTO> => this.get<TrainingCategoryDTO>(query);

	createTrainingCategory = (body: RequestBody): Promise<TrainingCategoryDTO> =>
		this.post<TrainingCategoryDTO>(body);

	updateTrainingCategory = (query: RequestQuery, body: RequestBody): Promise<TrainingCategoryDTO> =>
		this.put<TrainingCategoryDTO>(query, body);

	deleteTrainingCategory = (query: RequestQuery): Promise<DeleteTrainingCategoryDTO> =>
		this.delete<DeleteTrainingCategoryDTO>(query);
}

export { TrainingCategoriesService };
