import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { LeadsService } from 'services/leads-service';
import { FetchStatus } from 'utils/enums/fetch-status';
import { GET_LEADS } from 'utils/constants/end-points';
import { LeadsState } from 'utils/types/store';
import { RootState } from './index';

const initialState: LeadsState = {
	leadsList: [],
	status: FetchStatus.IDLE,
};

export const requestLeadsList = createAsyncThunk(
	'leads/list',
	async (query: Record<string, string>) => {
		const fetchInstance = new LeadsService(GET_LEADS);
		const result = await fetchInstance.leadsList(query);

		return result;
	},
);

const leadsSlice = createSlice({
	name: 'leads',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestLeadsList.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(requestLeadsList.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.leadsList = action.payload;
			});
	},
});

const getLeads = (state: RootState) => state.leads;

export const getLeadsList = createSelector(getLeads, leads => leads.leadsList);

export default leadsSlice.reducer;
