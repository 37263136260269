import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { BackButton, Button, ProfileDropdown, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RequestQuery } from 'utils/types/request-types';
import { TrainingCategoriesList, TrainingCategoriesListDTO } from 'utils/types/training-categories';
import {
	deleteTrainingCategory,
	getTrainingCategoriesList,
	requestTrainingCategories,
} from 'store/training-categories';
import { deleteOrange, editPurple } from 'assets/images';
import { userLevels } from 'utils/constants/user-levels';
import { columns } from './ManageCategoriesColumns';
import './ManageCategories.scss';

const ManageCategories = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector<TrainingCategoriesListDTO>(getTrainingCategoriesList);
	const Actions = ({ id }: RequestQuery): JSX.Element => (
		<>
			<Link to={`edit/${id}`}>
				<Button
					className="btn-link"
					icon={<img alt="" src={editPurple} />} text="Edit"
				/>
			</Link>
			<Button
				className="btn-link"
				icon={<img alt="" src={deleteOrange} />}
				onClick={() => dispatch(deleteTrainingCategory({ id }))}
				text="Delete"
			/>
		</>
	);

	const normalize = (coll: TrainingCategoriesListDTO): TrainingCategoriesList => coll.map(({
		id,
		title,
		level,
	}) => ({
		key: id,
		title,
		level: Object.keys(userLevels)[level],
		actions: <Actions id={`${id}`} />,
	}));

	useEffect(() => {
		dispatch(requestTrainingCategories());
	}, [dispatch]);

	const normalizedCategories: TrainingCategoriesList = normalize(categoriesList);

	return (
		<div className="manage-categories">
			<div className="row header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="manage-categories__top">
					<div className="page-title">
						<BackButton />
						<h1>Manage categories</h1>
					</div>
					<Link to="add-category">
						<Button className="btn-purple" text="Add Category" />
					</Link>
				</div>
				<div className="manage-categories__wrap">
					<Table
						columns={columns}
						data={normalizedCategories}
						scroll={{ y: '60vh' }}
					/>
				</div>
			</div>
		</div>
	);
};

export { ManageCategories };
