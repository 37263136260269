import { ProtectedImage } from 'components';
import { ImageContainer } from 'utils/types/common';

export const columns = [
	{
		title: 'Preview',
		dataIndex: 'previewImageId',
		key: 'previewImageId',
		render: (previewImageId: number) =>
			(<ProtectedImage containerType={ImageContainer.PREVIEW_IMAGES} id={previewImageId} />),
	},
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
	},
	{
		title: 'Category',
		dataIndex: 'categoryTitle',
		key: 'categoryTitle',
	},
	{
		title: 'Link',
		dataIndex: 'link',
		key: 'link',
		render: (link: string) => (
			<a href={link} target="_blank">
				{link}
			</a>
		),
	},
	{
		title: 'Level',
		dataIndex: 'level',
		key: 'level',
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
	},
];
