import { FetchAPI } from 'utils/fetch/fetch-api';
import {
	PendingUserApproveResponse,
	PendingUserRejectResponse,
	PendingUsersListDTO,
	UsersListDTO,
} from 'utils/types/users';
import { RequestBody, RequestQuery } from 'utils/types/request-types';

class UsersService extends FetchAPI {
	usersList = (query?: RequestQuery): Promise<UsersListDTO> => this.get<UsersListDTO>(query);

	lock = (query?: RequestQuery, body?: RequestBody): Promise<PendingUserRejectResponse> => (
		this.patch<PendingUserRejectResponse>(query, body)
	);

	unlock = (query?: RequestQuery, body?: RequestBody): Promise<PendingUserRejectResponse> => (
		this.patch<PendingUserRejectResponse>(query, body)
	);

	pendingUsersList = (): Promise<PendingUsersListDTO> => this.get<PendingUsersListDTO>();

	approve = (query?: RequestQuery, body?: RequestBody): Promise<PendingUserApproveResponse> => (
		this.patch<PendingUserApproveResponse>(query, body)
	);

	reject = (query?: RequestQuery, body?: RequestBody): Promise<PendingUserRejectResponse> => (
		this.patch<PendingUserRejectResponse>(query, body)
	);
}

export { UsersService };
