import { FetchAPI } from 'utils/fetch/fetch-api';
import { RequestBody } from 'utils/types/request-types';
import {
	CancelStripePayoutDTO,
	StripePayoutLinkDTO,
	StripePayoutStatusDTO,
} from 'utils/types/stripe';

class StripeService extends FetchAPI {
	requestStripePayout = (body: RequestBody): Promise<StripePayoutLinkDTO> =>
		this.post<StripePayoutLinkDTO>(body);

	getStripePayoutLink = (): Promise<StripePayoutLinkDTO> =>
		this.get<StripePayoutLinkDTO>();

	getStripePayoutStatus = (): Promise<StripePayoutStatusDTO> =>
		this.get<StripePayoutStatusDTO>();

	cancelStripePayout = (): Promise<CancelStripePayoutDTO> =>
		this.put<CancelStripePayoutDTO>();
}

export { StripeService };
