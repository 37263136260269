import {
	BarElement,
	CategoryScale, Chart as ChartJS, Legend,
	LinearScale, LineElement, PointElement, Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './Chart.scss';

type Props = {
	className?: string;
	labels: string[];
	data: number[];
};

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
);

const chartConfig = {
	borderColor: '#6848e5',
	pointRadius: 3,
	pointBorderColor: '#6848e5',
	pointBorderWidth: 2,
	pointBackgroundColor: '#fff',
	tension: 0.3,
};

const Chart = ({ className, data: dataset, labels, ...rest }: Props): JSX.Element => {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
	};

	const data = {
		labels,
		datasets: [
			{
				data: dataset,
				...chartConfig,
			},
		],
	};

	return (
		<div className={`chart ${className || ''}`} {...rest}>
			<Line data={data} options={options} />
		</div>
	);
};

export { Chart };
