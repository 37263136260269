export const messages = {
	username: {
		invalid: 'Invalid email address',
	},
	password: {
		isShort: 'Password is too short',
		isLong: 'Password is too long',
		notMatched: 'Passwords must match',
	},
	name: {
		isShort: 'Name is too short',
		isLong: 'Name is too long',
	},
	video: {
		title: {
			isShort: 'Title is too short',
			isLong: 'Title is too long',
		},
		description: {
			isShort: 'Description is too short',
			isLong: 'Description is too long',
		},
	},
};
