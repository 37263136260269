import {
	registrationLeftIco, registrationLeftIcoActive,
	registrationRightIco, registrationRightIcoActive,
} from 'assets/images';
import classNames from 'classnames';
import { Button, ProfileDropdown, Switch } from 'components';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
	// getPercent,
	// requestPercent,
	// updatePercent,
	getRegistrationFormStatus,
	requestRegistrationFormStatus,
	updateRegistrationFormStatus,
} from 'store/settings';
import { HTMLButtonTypes } from 'utils/enums/button-types';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { editSettingsSchema } from 'utils/validation/schemes';
import './Settings.scss';

const Settings = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const registrationFormStatus = useAppSelector<boolean>(getRegistrationFormStatus);
	// const percent = useAppSelector<number>(getPercent);

	type FormValues = {
		registrationStatus?: boolean;
		// percent?: number;
	};

	const initialValues: FormValues = {
		registrationStatus: registrationFormStatus,
		// percent: percent,
	};

	useEffect(() => {
		dispatch(requestRegistrationFormStatus());
		// dispatch(requestPercent());
	}, [dispatch]);

	const handleSubmit = async ({ registrationStatus }: FormValues,
		{ setSubmitting }: FormikHelpers<FormValues>) => {

		if (registrationStatus !== undefined) {
			try {
				await dispatch(updateRegistrationFormStatus({ registrationStatus })).unwrap();
				// await dispatch(updatePercent({ percent: Number(percent) })).unwrap();
				toast.success('Success!');
			} catch {
				toast.error('Failed!');
			}
			setSubmitting(false);
		}
	};

	const InnerForm = ({ dirty, isValid, isSubmitting }: FormikProps<FormValues>): JSX.Element => (
		<Form>
			<div className="registration-form-config__wrap">
				<div className="registration-form-config__text">
					{registrationFormStatus ? 'Registration is open' : 'Registration is closed'}
				</div>
				<div className="registration-form-config__switcher">
					<div className="registration-form-config__ico-wrap">
						<img
							alt=""
							className={classNames({ active: registrationFormStatus })}
							src={registrationRightIco}
						/>
						<img
							alt=""
							className={classNames({ active: !registrationFormStatus })}
							src={registrationRightIcoActive}
						/>
					</div>
					<Switch
						name="registrationStatus"
					/>
					<div className="registration-form-config__ico-wrap">
						<img
							alt=""
							className={classNames({ active: !registrationFormStatus })}
							src={registrationLeftIco}
						/>
						<img
							alt=""
							className={classNames({ active: registrationFormStatus })}
							src={registrationLeftIcoActive}
						/>
					</div>
				</div>
			</div>
			{/* <div className="percent-config__wrap">
				<div className="percent-config__text">
					<h2>Percent</h2>
				</div>
				<div className="percent-config__input">
					<InputText
						maxLength={3}
						name="percent"
						placeholder="Enter percent"
						type="number"
					/>
				</div>
			</div> */}
			<Button disabled={!dirty || !isValid || isSubmitting} htmlType={HTMLButtonTypes.SUBMIT} text="Submit" />
		</Form>
	);

	return (
		<div className="settings">
			<div className="header">
				<ProfileDropdown />
			</div>

			<div className="container">
				<div className="page-title">
					<h1>Settings</h1>
				</div>
				<div className="container-item__body">
					<Formik
						component={InnerForm}
						enableReinitialize={true}
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={editSettingsSchema}
					/>
				</div>
			</div>
		</div>
	);
};

export { Settings };
