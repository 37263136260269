import { Routes, Route } from 'react-router-dom';
import { Financial } from 'views/financial';
import { NotFound } from 'views/not-found';
import { CommissionsRoutes } from 'views/financial/commissions/CommissionsRoutes';
import { Sales } from './sales';

const FinancialRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Financial />} index />
		<Route element={<CommissionsRoutes />} path="commissions/*" />
		<Route element={<Sales />} path="sales" />

		<Route element={<NotFound />} path="*" />
	</Routes>
);

export { FinancialRoutes };
