import { Link } from 'react-router-dom';

const NotFound = (): JSX.Element => (
	<div className="not-found">
		<h2>Not Found</h2>
		<p>
			<Link to="/">Go to the home page</Link>
		</p>
	</div>
);

export { NotFound };

